import { IBackStory } from "@src/components/Backstory";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectCreative, Pagination, Autoplay, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/effect-creative";

const Backstory: React.FC<IBackStory> = ({ eventId, backStoryData }) => {
  return !!backStoryData?.getBackStory &&
    backStoryData?.getBackStory?.length > 0 ? (
    <Swiper
      loop
      modules={[Navigation, EffectCreative, Pagination, Autoplay, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      grabCursor={true}
      effect={"creative"}
      creativeEffect={{
        prev: {
          shadow: true,
          translate: [0, 0, -400],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      }}
      autoplay={{
        delay: 20000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      className={"w-full relative "}
    >
      {backStoryData?.getBackStory?.map((story, idx) => (
        <SwiperSlide
          key={`Swiper-slide-${idx}`}
          className={
            "w-full h-full bg-cover relative before:absolute before:w-ful before:h-full  bg-bottom bg-no-repeat bg-bg-backstory-t2 "
          }
        >
          <div className="w-full h-full bg-inawo-pink-300/80">
            <div className="py-[7.5rem]  container max-w-[58.875rem] flex flex-col items-center justify-center gap-[2rem] min-h-[45.5rem] text-inawo-black-900/80">
              <h3 className="font-cinzel text-[1.5rem] md:text-[2.25rem]  font-[700]">{`How We Met`}</h3>
              <p className="font-temp font-[300]   text-justify text-[1rem] md:text-[1.5rem] leading-[175%]">
                {story?.story}
              </p>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  ) : (
    <></>
  );
};

export default Backstory;
