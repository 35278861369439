import * as React from "react";
import styles from "./styles.module.scss";
import Icon from "@src/components/Icon";
// import Boop from "@src/components/Boop";
// import Dialog from "@src/components/Dialog";
// import ExperienceModal from "@src/components/modals/ExperienceAsGuestModal";
import { useRouter } from "next/router";
import useBoop from "@hooks/useBoop";
import { animated } from "react-spring";
import { eventBySlug } from "@src/@types/api.d";

type HeaderProps = {
  eventId: number;
  slugData: eventBySlug | undefined;
};

const Header: React.FC<HeaderProps> = ({ eventId, slugData }) => {
  const [style, trigger] = useBoop({ rotation: -3 });
  // const [open, setOpen] = React.useState<boolean>(false);
  const router = useRouter();

  const GoHome = () => {
    router.push("/");
  };

  return (
    <div className={styles["header"]}>
      <header className={styles["header__header"]}>
        <button
          onClick={GoHome}
          type="button"
          aria-label="home"
          className={styles["header__header-title"]}
          onMouseEnter={trigger}
        >
          <div className={styles["header__header-title-icon"]}>
            <animated.span style={style}>
              <Icon iconName="leftLeaf" />
            </animated.span>
          </div>
          <h4 className={styles["header__header-title-text"]}>
            {slugData?.eventBySlug?.name ?? "David & Kemi"}
          </h4>
        </button>

        {/* <div className={styles["header__header-notifications-container"]}>
					<Boop rotation={-6}>
						<div className={styles["header__header-notifications"]}>
							<Icon iconName="notifications" />
						</div>
					</Boop>

					<div className={styles["header__header-notifications-button"]}>
					<Dialog
              trigger={
                <div className={styles["header__header-notifications-button__btn"]}>
                  Invited ?
                </div>
              }
              open={open}
              onOpenChange={setOpen}
            >
              <ExperienceModal eventId={eventId} setOpen={setOpen} />
					</Dialog>
					</div>
				</div> */}
      </header>
    </div>
  );
};

export default Header;
