import * as React from "react";
import CashGiftPayment from "./modals/CashGiftPayment";
import ForeignDetails from "./modals/ForeignDetails";
import styles from "./cashGift.module.scss";
import Icon from "@src/components/Icon";
import Boop from "@src/components/Boop";
import {
  Currency,
  getForeignAccountDetails_getForeignAccountDetails,
} from "@src/@types/api.d";

interface CashGiftModalProps {
  eventId: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  foreignDetails:
    | getForeignAccountDetails_getForeignAccountDetails
    | null
    | undefined;
}

type CashGiftModalInterface = "foreign" | "naira" | "option";

interface CashGiftOptionInterface {
  setView: (i: CashGiftModalInterface) => void;
  foreignDetails:
    | getForeignAccountDetails_getForeignAccountDetails
    | null
    | undefined;
}

const CashGiftOption = ({
  setView,
  foreignDetails,
}: CashGiftOptionInterface) => {
  return (
    <div className={styles["cashGift--content"]}>
      <div className={styles["cashGift--content__optionView"]}>
        <Boop y={2}>
          <div
            className={styles["option"]}
            onClick={() => {
              setView("naira");
            }}
          >
            <Icon iconName="nairaGift" />
            <span>Send Naira</span>
          </div>
        </Boop>
        <Boop y={2}>
          <div
            className={styles["option"]}
            onClick={() => {
              setView("foreign");
            }}
          >
            <Icon iconName="dollarGift" />
            <span>
              Send{" "}
              {`${
                foreignDetails?.currency === Currency.usd ? `Dollar` : `Pound`
              }`}
            </span>
          </div>
        </Boop>
      </div>
    </div>
  );
};

const CashGiftModal = ({
  eventId,
  setOpen,
  foreignDetails,
}: CashGiftModalProps) => {
  const [cashGiftView, setCashGiftView] =
    React.useState<CashGiftModalInterface>("option");

  React.useEffect(() => {
    if (!foreignDetails) {
      setCashGiftView("naira");
    }
  }, [foreignDetails]);

  const view: Record<CashGiftModalInterface, JSX.Element> = {
    foreign: <ForeignDetails foreignDetails={foreignDetails} />,
    naira: <CashGiftPayment eventId={eventId} setOpen={setOpen} />,
    option: (
      <CashGiftOption
        foreignDetails={foreignDetails}
        setView={(i) => setCashGiftView(i)}
      />
    ),
  };

  return view[cashGiftView];
};

export const MemoCashGiftModal = React.memo(CashGiftModal);
