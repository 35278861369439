import type { NextPage, GetServerSideProps } from "next";
import Home from "@src/components/Home";
import HomeT2 from "@src/components/Template2/Home";
import HomeT3 from "@src/components/Template3/Home";
import { getCookie, deleteCookie, setCookie } from "cookies-next";
import {
  EventBySlug,
  EventParts,
  EventVendor,
  GetAlbumDetails,
  GetBackStory,
  GetEventStreamers,
  GetHeroImage,
  GetLiveSchedule,
  Refresh,
} from "@src/graphql/queries";
import initApolloClient, {
  createApolloClient,
  withApollo,
} from "../apollo-client";
import {
  eventBySlug,
  eventBySlugVariables,
  eventParts,
  eventPartsVariables,
  eventVendor,
  eventVendorVariables,
  getAlbumDetails,
  getAlbumDetailsVariables,
  getBackStory,
  getBackStoryVariables,
  getEventStreamers,
  getEventStreamersVariables,
  getHeroImage,
  getHeroImageVariables,
  getLiveSchedule,
  getLiveScheduleVariables,
  refresh,
  refreshVariables,
  signupByInviteLink,
  signupByInviteLinkVariables,
} from "@src/@types/api.d";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { SignUpByInviteLink } from "@src/graphql/mutations";
import Head from "next/head";
import { useRouter } from "next/router";
import * as React from "react";
import toast from "react-hot-toast";

type IndexType = {
  slug: string;
  event_id_query: number;
  refreshTokenExpired: boolean;
  hasWallet: boolean;
  eventBySlugData?: eventBySlug;
  weddingName?: string;
  weddingMsg?: string;
  eventPartsData: eventParts;
  backStoryData: getBackStory;
  eventVendorData: eventVendor;
  albumDetailsData: getAlbumDetails;
  heroImageData: getHeroImage;
  getEventStreamersData: getEventStreamers;
  getLiveScheduleData: getLiveSchedule;
};

const Index: NextPage<IndexType> = ({
  slug,
  event_id_query,
  eventBySlugData,
  weddingName,
  weddingMsg,
  eventPartsData,
  backStoryData,
  eventVendorData,
  albumDetailsData,
  heroImageData,
  refreshTokenExpired,
  hasWallet,
  getEventStreamersData,
  getLiveScheduleData,
}) => {
  const router = useRouter();

  React.useEffect(() => {
    router.replace("/", undefined, { shallow: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (refreshTokenExpired) {
      toast(
        <p className="toast">
          You&apos;re currently logged out. <br />
          To refresh, use the <span className="bold"> invite link </span> or
          open this event from your{" "}
          <span className="bold">Inawo dashboard</span>.
        </p>,
        {
          duration: 10000,
          style: {
            background: "#fff8e6",
            border: "0.5px dashed #ffb800",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: "12px 8px",
            fontFamily: "Nunito Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "19px",
            textAlign: "center",
            color: "#996e00",
            borderRadius: "0px",
          },
        }
      );
    }
  }, [refreshTokenExpired]);

  return (
    <>
      <Head>
        <title>{weddingName}</title>
        <meta name="application-name" content={weddingName} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content={weddingName} />
        <meta name="description" content={weddingMsg} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta name="msapplication-tap-highlight" content="no" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content={`"https://${slug}.inawo.wedding"`} />
        <meta name="twitter:title" content={weddingName} />
        <meta name="twitter:description" content={weddingMsg} />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/drqltx8ye/image/upload/v1636616812/Cover_pcqimz.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={weddingName} />
        <meta property="og:description" content={weddingMsg} />
        <meta property="og:site_name" content={weddingName} />
        <meta property="og:url" content={`"https://${slug}.inawo.wedding"`} />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/drqltx8ye/image/upload/v1636616812/Cover_pcqimz.png"
        />
      </Head>
      {eventBySlugData?.eventBySlug?.template_id === "assistant-pilot" ? (
        <HomeT3
          slug={slug}
          hasWallet={hasWallet}
          eventId={event_id_query}
          eventBySlugData={eventBySlugData}
          eventPartsData={eventPartsData}
          backStoryData={backStoryData}
          eventVendorData={eventVendorData}
          albumDetailsData={albumDetailsData}
          heroImageData={heroImageData}
          getEventStreamersData={getEventStreamersData}
          liveSchedules={getLiveScheduleData}
        />
      ) : eventBySlugData?.eventBySlug?.template_id === "pilot" ? (
        <HomeT2
          slug={slug}
          hasWallet={hasWallet}
          eventId={event_id_query}
          eventBySlugData={eventBySlugData}
          eventPartsData={eventPartsData}
          backStoryData={backStoryData}
          eventVendorData={eventVendorData}
          albumDetailsData={albumDetailsData}
          heroImageData={heroImageData}
          getEventStreamersData={getEventStreamersData}
          liveSchedules={getLiveScheduleData}
        />
      ) : (
        <Home
          slug={slug}
          hasWallet={hasWallet}
          eventId={event_id_query}
          eventBySlugData={eventBySlugData}
          eventPartsData={eventPartsData}
          backStoryData={backStoryData}
          eventVendorData={eventVendorData}
          albumDetailsData={albumDetailsData}
          heroImageData={heroImageData}
          getEventStreamersData={getEventStreamersData}
          liveSchedules={getLiveScheduleData}
        />
      )}
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const subdomain = context?.req?.headers?.host?.split(".")[0] ?? "";

  if (!subdomain || subdomain === "www") {
    return {
      redirect: {
        destination:
          process.env.NEXT_PUBLIC_API_LANDING_URL || "https://inawo.com/",
        permanent: true,
      },
    };
  }

  let refreshTokenExpired = false;

  const query = context.query;
  const req = context.req;
  const res = context.res;

  // coming from the invite link
  const otp = query?.token as string;
  const eventId = query?.eventId as string;

  // coming from the dashboard
  const refreshToken = query?.evd as string;

  // these can still be undefined
  let currentAccessToken = getCookie("tsl", { req, res }) as string;
  let currentRefreshToken = getCookie("evd", { req, res }) as string;

  const client: ReturnType<typeof createApolloClient> = initApolloClient(
    {},
    !!currentAccessToken ? currentAccessToken : "",
    context
  );

  if (!!otp) {
    // remove previously set cookies if they exist
    deleteCookie("tsl", { req, res });
    deleteCookie("usI", { req, res });
    deleteCookie("evd", { req, res });

    try {
      const { data, errors } = await client.mutate<
        signupByInviteLink,
        signupByInviteLinkVariables
      >({
        mutation: SignUpByInviteLink,
        variables: {
          inviteToken: otp,
          eventId: Number(eventId),
        },
      });

      if (!!data && data.signupByInviteLink) {
        currentAccessToken = data.signupByInviteLink.access_token;
        currentRefreshToken = data.signupByInviteLink.refresh_token;

        setCookie("evd", data.signupByInviteLink.refresh_token, {
          req,
          res,
          maxAge: 90 * 60 * 60 * 24,
        });
        setCookie("tsl", data.signupByInviteLink.access_token, {
          req,
          res,
          maxAge: 90 * 60 * 60 * 24,
        });
        setCookie("usI", data.signupByInviteLink?.user?.id, {
          req,
          res,
          maxAge: 90 * 60 * 60 * 24,
        });
      }

      if (errors) {
        console.error("Invite token error:>> ", errors);
      }
    } catch (error) {
      console.error("Invite token error:>> ", error);
    }
  }

  if (!!refreshToken) {
    // remove previously set cookies if they exist
    deleteCookie("tsl", { req, res });
    deleteCookie("usI", { req, res });
    setCookie("evd", refreshToken, { req, res, maxAge: 90 * 60 * 60 * 24 });

    try {
      const { data, error } = await client.query<refresh, refreshVariables>({
        query: Refresh,
        variables: {
          refreshToken,
        },
      });

      if (!!data) {
        currentAccessToken = data.refresh.access_token;
        currentRefreshToken = data.refresh.refresh_token;

        setCookie("evd", data.refresh.refresh_token, {
          req,
          res,
          maxAge: 90 * 60 * 60 * 24,
        });
        setCookie("tsl", data.refresh.access_token, {
          req,
          res,
          maxAge: 90 * 60 * 60 * 24,
        });
        setCookie("usI", data.refresh?.user?.id, {
          req,
          res,
          maxAge: 90 * 60 * 60 * 24,
        });
      }

      if (error) {
        console.error("Refresh string error:>> ", error);
      }
    } catch (error) {
      console.error("Refresh string error:>> ", error);
    }
  }

  if (!!currentRefreshToken) {
    let decodedRefreshJwt = jwt_decode<JwtPayload>(
      currentRefreshToken as string
    );

    if (!!decodedRefreshJwt.exp && decodedRefreshJwt.exp * 1000 < Date.now()) {
      refreshTokenExpired = true;

      deleteCookie("tsl", { req, res });
      deleteCookie("usI", { req, res });
      deleteCookie("evd", { req, res });

      return {
        redirect: {
          destination:
            process.env.NEXT_PUBLIC_API_LANDING_URL || "https://inawo.com/",
          permanent: false,
        },
      };
    }
  }

  if (!!currentAccessToken) {
    let decodedAccessJwt = jwt_decode<JwtPayload>(currentAccessToken as string);

    if (
      !!decodedAccessJwt.exp &&
      decodedAccessJwt.exp * 1000 < Date.now() - 10000
    ) {
      try {
        const { data, error } = await client.query<refresh, refreshVariables>({
          query: Refresh,
          variables: {
            refreshToken: currentRefreshToken,
          },
        });

        if (!!data) {
          currentAccessToken = data.refresh.access_token;
          currentRefreshToken = data.refresh.refresh_token;

          setCookie("evd", data.refresh.refresh_token, {
            req,
            res,
            maxAge: 90 * 60 * 60 * 24,
          });
          setCookie("tsl", data.refresh.access_token, {
            req,
            res,
            maxAge: 90 * 60 * 60 * 24,
          });
          setCookie("usI", data.refresh?.user?.id, {
            req,
            res,
            maxAge: 90 * 60 * 60 * 24,
          });
        }

        if (error) {
          console.error("Refresh error:>> ", error);
          deleteCookie("evd");
          deleteCookie("tsl");
          deleteCookie("usI");
          refreshTokenExpired = true;
        }
      } catch (error) {
        console.error("Refresh error:>> ", error);
        deleteCookie("evd");
        deleteCookie("tsl");
        deleteCookie("usI");
        refreshTokenExpired = true;
      }
    }
  }

  let event_id = eventId || ("" as string | number);
  let eventUuid = "";
  let weddingName = "";
  let weddingMsg = "";
  let hasWallet = false;

  let eventBySlugData = {} as eventBySlug;
  let eventPartsData = {} as eventParts;
  let backStoryData = {} as getBackStory;
  let albumDetailsData = {} as getAlbumDetails;
  let eventVendorData = {} as eventVendor;
  let heroImageData = {} as getHeroImage;
  let getEventStreamersData = {} as getEventStreamers;
  let getLiveScheduleData = {} as getLiveSchedule;

  try {
    const { data } = await client.query<eventBySlug, eventBySlugVariables>({
      query: EventBySlug,
      variables: {
        slug: subdomain,
      },
    });

    if (data) {
      eventBySlugData = data;
    }
  } catch (error) {
    console.error("Event Slug error:>> ", error);

    return {
      redirect: {
        destination:
          process.env.NEXT_PUBLIC_API_LANDING_URL || "https://inawo.com/",
        permanent: false,
      },
    };
  }

  event_id =
    !!eventBySlugData && !!eventBySlugData?.eventBySlug?.id
      ? eventBySlugData?.eventBySlug?.id
      : !!eventId
      ? eventId
      : 0;

  eventUuid =
    !!eventBySlugData && !!eventBySlugData?.eventBySlug?.uuid
      ? eventBySlugData?.eventBySlug?.uuid
      : "";

  weddingName =
    !!eventBySlugData && !!eventBySlugData.eventBySlug?.name
      ? `${eventBySlugData.eventBySlug?.name}`
      : "Inawo Wedding";

  weddingMsg =
    !!eventBySlugData && !!eventBySlugData.eventBySlug?.welcome_msg
      ? `${eventBySlugData.eventBySlug?.welcome_msg}`
      : "Inawo Wedding";

  hasWallet =
    !!eventBySlugData &&
    !!eventBySlugData.eventBySlug?.event_wallet &&
    eventBySlugData.eventBySlug?.event_wallet?.length > 0
      ? true
      : false;

  try {
    const responses = await Promise.allSettled([
      client.query<getHeroImage, getHeroImageVariables>({
        query: GetHeroImage,
        variables: { eventId: Number(event_id) },
      }),
      client.query<getEventStreamers, getEventStreamersVariables>({
        query: GetEventStreamers,
        variables: { eventId: Number(event_id) },
      }),
      client.query<eventParts, eventPartsVariables>({
        query: EventParts,
        variables: { eventId: Number(event_id) },
      }),
      client.query<getBackStory, getBackStoryVariables>({
        query: GetBackStory,
        variables: { eventId: Number(event_id) },
      }),
      client.query<getAlbumDetails, getAlbumDetailsVariables>({
        query: GetAlbumDetails,
        variables: { eventUuid: eventUuid, page: 1, pageSize: 60 },
      }),
      client.query<eventVendor, eventVendorVariables>({
        query: EventVendor,
        variables: { eventId: Number(event_id), page: 1, pageSize: 60 },
      }),
      client.query<getLiveSchedule, getLiveScheduleVariables>({
        query: GetLiveSchedule,
        variables: { eventUuid: eventUuid, page: 1, pageSize: 60 },
      }),
    ]);

    responses.forEach((response, index) => {
      if (response.status === "fulfilled") {
        const { data } = response.value;
        switch (index) {
          case 0:
            heroImageData = data as getHeroImage;
            break;
          case 1:
            getEventStreamersData = data as getEventStreamers;
            break;
          case 2:
            eventPartsData = data as eventParts;
            break;
          case 3:
            backStoryData = data as getBackStory;
            break;
          case 4:
            albumDetailsData = data as getAlbumDetails;
            break;
          case 5:
            eventVendorData = data as eventVendor;
            break;
          case 6:
            getLiveScheduleData = data as getLiveSchedule;
            break;
          default:
            break;
        }
      } else {
        const error = response.reason;
        switch (index) {
          case 0:
            console.error("HeroImage error:", error);
            break;
          case 1:
            console.error("Get Streamers error:", error);
            break;
          case 2:
            console.error("Parts error:", error);
            break;
          case 3:
            console.error("Backstory error:", error);
            break;
          case 4:
            console.error("Album error:", error);
            break;
          case 5:
            console.error("Vendor error:", error);
            break;
          case 6:
            console.log("Live Schedule Error:", error);
            break;
          default:
            break;
        }
      }
    });
  } catch (error) {
    console.error("Error:", error);
  }

  return {
    props: {
      slug: subdomain || "",
      event_id_query: Number(eventId) || Number(event_id) || 0,
      getEventStreamersData,
      eventBySlugData,
      weddingName,
      weddingMsg,
      eventPartsData,
      backStoryData,
      eventVendorData,
      albumDetailsData,
      heroImageData,
      refreshTokenExpired,
      getLiveScheduleData,
      hasWallet,
      apolloStaticCache: client.cache.extract(true),
      token: !!currentAccessToken ? currentAccessToken : "",
      refreshToken: !!currentRefreshToken ? currentRefreshToken : "",
    },
  };
};

export default withApollo(Index);
