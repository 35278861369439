import * as React from "react";
import Button from "@src/components/Button";
import invitation from "./invitation.module.scss";
import toast from "react-hot-toast";
import Image from "next/image";
import { useQuery, useMutation } from "@apollo/client";
import {
  invitationCard,
  invitationCardVariables,
  updateRsvpStatus,
  updateRsvpStatusVariables,
} from "@src/@types/api.d";
import { InvitationCard } from "@src/graphql/queries";
import { UpdateRsvpStatus } from "@src/graphql/mutations";
import { getCookie } from "cookies-next";
import defaultInviteImage from "../../../../public/images/generalDefaultImage.png";

interface InvitationProps {
  name: string | null;
  date: string;
  eventId: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Invitation = ({ date, name, eventId, setOpen }: InvitationProps) => {
  const currentAccessToken = getCookie("tsl") as string;

  const [show, setShow] = React.useState<boolean>(false);

  React.useEffect(() => {
    setShow(true);
  }, []);

  const { data: invitationCardData, loading: invitationCardLoading } = useQuery<
    invitationCard,
    invitationCardVariables
  >(InvitationCard, {
    skip: !eventId,
    variables: {
      eventId,
    },
  });

  const [UpdateRsvpStatusFn, { loading }] = useMutation<
    updateRsvpStatus,
    updateRsvpStatusVariables
  >(UpdateRsvpStatus, {
    onCompleted() {
      setOpen(false);
      toast.success(<p className="toast">RSVP sent successfully.</p>);
    },
    onError(error) {
      if (error.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      }
    },
  });

  return (
    <div className={invitation["invitation--content"]}>
      {invitationCardLoading ? (
        <>Loading...</>
      ) : !!invitationCardData?.invitationCard?.template ? (
        <div className={invitation["invitation--content__image"]}>
          <Image
            src={invitationCardData?.invitationCard?.template}
            layout="fill"
            alt="Invitation card"
            objectFit="contain"
          />
        </div>
      ) : (
        <div className={invitation["invitation--content__image"]}>
          <Image
            src={defaultInviteImage}
            layout="fill"
            alt="Invitation card"
            objectFit="contain"
          />
        </div>
      )}

      {show && !!currentAccessToken && (
        <div className={invitation["invitation--content__bottom"]}>
          <div>
            <Button
              loading={loading}
              handleClick={() =>
                UpdateRsvpStatusFn({
                  variables: {
                    eventId: eventId,
                    status: "yes",
                  },
                })
              }
              type="button"
              design="another"
              label="I'm Coming"
            >
              I&apos;m Coming
            </Button>
          </div>
          <div>
            <Button
              loading={loading}
              handleClick={() =>
                UpdateRsvpStatusFn({
                  variables: {
                    eventId: eventId,
                    status: "no",
                  },
                })
              }
              type="button"
              design="secondary"
              label="I'm Not Coming"
            >
              I&apos;m Not Coming
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Invitation;
