import React from "react";
import Header from "@src/components/Header";
import Floater from "@src/components/Floater";
import Footer from "@src/components/Footer";
import {
  eventBySlug,
  eventParts,
  eventPreferences,
  eventVendor,
  getAlbumDetails,
  getApprovedMessages,
  getBackStory,
  getEventStreamers,
  getHeroImage,
  getLiveSchedule,
} from "src/@types/api";
import Hero from "@src/components/Hero";
import Quotes from "@src/components/Quotes";
import Backstory from "@src/components/Backstory";
import MarqueeComp from "@src/components/Marquee";
import Masonry from "@src/components/Masonry";
import Vendors from "@src/components/Vendors";
import Parts from "@src/components/Parts";
import ClientOnly from "@src/components/ClientOnly";

export type IHome = {
  slug: string;
  eventBySlugData?: eventBySlug;
  eventId: number;
  eventPartsData: eventParts;
  backStoryData: getBackStory;
  eventVendorData: eventVendor;
  albumDetailsData: getAlbumDetails;
  heroImageData: getHeroImage;
  hasWallet: boolean;
  getEventStreamersData: getEventStreamers;
  liveSchedules: getLiveSchedule;
};

const Home: React.FC<IHome> = ({
  slug,
  eventBySlugData,
  eventId,
  eventPartsData,
  backStoryData,
  eventVendorData,
  albumDetailsData,
  heroImageData,
  hasWallet,
  getEventStreamersData,
  liveSchedules,
}) => {
  return (
    <>
      <Header eventId={eventId} slugData={eventBySlugData} />
      <ClientOnly>
        <Floater
          eventId={eventId}
          slugData={eventBySlugData}
          hasWallet={hasWallet}
          getEventStreamersData={getEventStreamersData}
          liveSchedules={liveSchedules}
        />
      </ClientOnly>
      <Hero
        heroImageData={heroImageData}
        eventId={eventId}
        slugData={eventBySlugData}
      />
      <Parts
        liveSchedules={liveSchedules}
        eventId={eventId}
        eventPartsData={eventPartsData}
      />
      <Quotes />
      <Backstory eventId={eventId} backStoryData={backStoryData} />
      <ClientOnly>
        <MarqueeComp eventId={eventId} slugData={eventBySlugData} />
      </ClientOnly>
      <Masonry
        eventSlug={slug}
        eventId={eventId}
        albumDetailsData={albumDetailsData}
      />
      <Vendors eventId={eventId} eventVendorData={eventVendorData} />
      <Footer />
    </>
  );
};

export default Home;
