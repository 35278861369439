import Icon from "../Icon";
import styles from "./quotes.module.scss";
import { motion } from "framer-motion";

type Props = {};

const Quotes = (props: Props) => {
  return (
    <div className={styles["home__quotes"]}>
      <div className={styles["home__quotes-container"]}>
        <div className={styles["home__quotes-box"]}>
          <motion.div
            initial={{
              opacity: 0,
              y: 15,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                delay: 0.3,
                duration: 0.7,
                ease: [0.79, 0.14, 0.15, 0.86],
              },
            }}
            className={styles["home__quotes-box-icon"]}
          >
            <Icon iconName="wine" />
          </motion.div>

          <motion.div
            initial={{ opacity: 1 }}
            whileInView={{
              opacity: 1,
              transition: {
                delayChildren: 1,
              },
            }}
            className={styles["home__quotes-box-text__container"]}
          >
            <motion.div
              initial={{
                y: 100,
              }}
              animate={{
                y: 0,
                transition: {
                  delay: 0.5,
                  duration: 0.8,
                  ease: [0.79, 0.14, 0.15, 0.86],
                },
              }}
              className={styles["home__quotes-box-text"]}
            >
              My heart is yours to hold
            </motion.div>
          </motion.div>

          <motion.div className={styles["home__quotes-box-text__container"]}>
            <motion.div
              initial={{
                y: 100,
              }}
              animate={{
                y: 0,
                transition: {
                  delay: 0.9,
                  duration: 0.8,
                  ease: [0.79, 0.14, 0.15, 0.86],
                },
              }}
              className={styles["home__quotes-box-text"]}
            >
              and cherish for the rest of our days.
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className={styles["home__quotes-left-flower"]}>
        <Icon iconName="leftQuotesFlower" />
      </div>

      <div className={styles["home__quotes-right-flower"]}>
        <Icon iconName="rightQuotesFlower" />
      </div>
    </div>
  );
};

export default Quotes;
