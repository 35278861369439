import * as React from "react";
import dynamic from "next/dynamic";
import IVSPlayer from "@src/components/IVSPlayer";
import useScript from "@src/hooks/useScript";
import livehall from "./livehallModal.module.scss";
import Icon from "@src/components/Icon";

interface LivehallModalProps {
  eventId: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  src: string | undefined;
  isLive: boolean;
}

const Livehall = ({ isLive, src, setOpen }: LivehallModalProps) => {
  // Load IVS tech
  const { loading, error } = useScript({
    src: "https://player.live-video.net/1.2.0/amazon-ivs-videojs-tech.min.js",
  });

  // Load IVS quality plugin
  const { loading: loadingPlugin, error: pluginError } = useScript({
    src: "https://player.live-video.net/1.2.0/amazon-ivs-quality-plugin.min.js",
  });

  if (loading || loadingPlugin) {
    return <div className={livehall["livehall__no-video"]}>Loading...</div>;
  }

  if (error || pluginError) {
    return <div className={livehall["livehall__no-video"]}>Error!</div>;
  }

  return (
    <div className={livehall["livehall"]}>
      {isLive ? (
        <>
          <button
            className={livehall["livehall__leave"]}
            onClick={() => setOpen(false)}
          >
            Leave Wedding
          </button>
          <div className={livehall["livehall__video"]}>
            <IVSPlayer isLive={isLive} src={!!src ? src : ""} />
          </div>
        </>
      ) : (
        <div className={livehall["livehall__no-video"]}>
          <Icon iconName="noVideo" />
          <h3>Seems the wedding hasn’t started</h3>
          <p>
            The livestream hasn’t started yet. A mail would be sent to you once
            it begins
          </p>
        </div>
      )}
    </div>
  );
};

export const MemoLivehallModal = React.memo(Livehall);
