import * as React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "@src/components/Button";
import messageModal from "./messageModal.module.scss";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { CreateMessage } from "@src/graphql/mutations";
import { createMessage, createMessageVariables } from "src/@types/api";
import { GetApprovedMessages } from "@src/graphql/queries";

interface Values {
  message: string | any;
}

const GeneralFormSchema = Yup.object().shape({
  message: Yup.string()
    .max(240, "Your message should be a maximum of 240 characters.")
    .required("Message is required"),
});

interface MessageModalProps {
  name: string | null;
  eventId: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MessageModal = ({ name, eventId, setOpen }: MessageModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm<Values>({
    mode: "onChange",
    defaultValues: {
      message: "",
    },
    resolver: yupResolver(GeneralFormSchema),
  });

  const [CreateMessageFn, { loading }] = useMutation<
    createMessage,
    createMessageVariables
  >(CreateMessage, {
    onCompleted() {
      setOpen(false);
      toast.success(<p className="toast">Message sent successfully.</p>);
    },
    onError(error) {
      console.error("Create message:>> ", error);

      if (error.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      }
    },
  });

  const onSubmit: SubmitHandler<Values> = (data) => {
    CreateMessageFn({
      variables: {
        eventId,
        content: data.message,
      },
      refetchQueries: [
        {
          query: GetApprovedMessages,
          variables: {
            eventId,
          },
        },
      ],
    });
  };
  return (
    <div className={messageModal["messageModal--content"]}>
      <div className={messageModal["messageModal--content__top"]}>
        <h1>Write {!!name && `to ${name}`}</h1>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={messageModal["messageModal--content-form"]}
      >
        <div className={messageModal["messageModal--content-form_container"]}>
          <textarea
            {...register("message")}
            rows={5}
            name="message"
            className={messageModal["messageModal--content-form__field"]}
            placeholder="Type your message here"
          />
          <p className={messageModal["messageModal--content-form__max"]}>
            Max: 240 characters
          </p>
          <p className={messageModal["messageModal--content-form__errors"]}>
            {errors.message?.message} &nbsp;
          </p>
        </div>
        <div
          className={messageModal["messageModal--content-form-btnContainer"]}
        >
          <div>
            <Button
              loading={loading}
              type="submit"
              label="Send Message"
              disabled={!isDirty || isSubmitting || !isValid}
            >
              {isSubmitting ? "Sending..." : "Send Message"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export const MemoMessageModal = React.memo(MessageModal);
