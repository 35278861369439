import * as React from "react";
import { animated } from "react-spring";
import useBoop from "@hooks/useBoop";

type BoopProps = {
  children: React.ReactNode;
  x?: number;
  y?: number;
  rotation?: number;
  scale?: number;
  timing?: number;
  springConfig?: {
    tension?: number;
    friction?: number;
  };
};

const Boop: React.FC<BoopProps> = ({ children, ...boopConfig }) => {
  const [style, trigger] = useBoop(boopConfig);

  return (
    <animated.span
      onMouseEnter={trigger as React.MouseEventHandler<HTMLSpanElement>}
      style={style}
    >
      {children}
    </animated.span>
  );
};

export default Boop;
