import { IHome } from "@src/components/Home";
import React from "react";
import ClientOnly from "@src/components/ClientOnly";
import Floater from "../Floater";
import { shimmer, toBase64 } from "@src/utils/shimmer";
import image from "../../../../public/images/image13.jpeg";
import demoImage1 from "../../../../public/images/t3-demo-1.jpg";
import demoImage2 from "../../../../public/images/t3-demo-2.jpg";
import Image from "next/image";
import { useTransform, useViewportScroll, motion } from "framer-motion";
import Button from "@src/components/Button";
import Link from "next/link";
import { truncate } from "lodash";
import DialogModal from "@src/components/Dialog";
import BackstoryModal from "@src/components/modals/BackstoryModal";

const Home: React.FC<IHome> = ({
  slug,
  eventBySlugData,
  eventId,
  eventPartsData,
  backStoryData,
  eventVendorData,
  albumDetailsData,
  heroImageData,
  hasWallet,
  getEventStreamersData,
  liveSchedules,
}) => {
  const weddingColors: Record<number, string[]> = {
    2: ["#CEB797", "#FEE0E0", "#E8B6B7", "#74041D", "#550B1C"],
    3: ["#C6AD6A", "#FE5D02", "#F7D851", "#FF6C84", "#FE353D"],
  };

  const eventsPartsWithColors = eventPartsData?.eventParts?.map((part, idx) => {
    const colors = weddingColors[idx + 1];
    return {
      ...part,
      colors,
    };
  });

  return (
    <ClientOnly>
      <Floater
        eventId={eventId}
        slugData={eventBySlugData}
        hasWallet={hasWallet}
        getEventStreamersData={getEventStreamersData}
        liveSchedules={liveSchedules}
      />
      <div className="flex container flex-col relative gap-8 py-8">
        <section className="w-full flex flex-col py-2 border-t-4  border-black">
          <div className="py-2 flex border-black   border-t border-b items-center justify-between">
            <h2 className="md:text-[1.5rem] font-neue font-[700]">
              DECEMBER, 2024
            </h2>
            <h2 className="md:text-[1.5rem] font-neue font-[700]">
              LAGOS NIGERIA
            </h2>
          </div>
          <div className="flex flex-col py-4 items-center gap-2  border-black">
            <h2 className="text-center font-oldEnglish text-[2.5rem] md:text-[4rem]">
              The Intimate Wedding Post
            </h2>
            <h5 className="text-[1.25rem] md:text-[1.5rem] font-neue font-bold">
              SAVE THE DATE
            </h5>
          </div>
          <div className="flex flex-col py-2 border-y border-y-black">
            <div className="flex flex-col py-8 border-y-4 border-y-black relative">
              <div className="relative h-[500px] md:h-[700px] w-full bg-slate-300 overflow-hidden">
                <motion.div
                  initial={{ scale: 1 }}
                  animate={{
                    scale: 1.1,
                    transition: {
                      delay: 0.3,
                      duration: 0.8,
                      ease: [0.79, 0.14, 0.15, 0.86],
                    },
                  }}
                  className={
                    "relative top-0 bottom-0 right-0  left-0 w-full h-full after:absolute after:w-full after:h-full after:bg-black/40"
                  }
                >
                  <Image
                    placeholder="blur"
                    loading="eager"
                    layout="fill"
                    blurDataURL={`data:image/svg+xml;base64,${toBase64(
                      shimmer(700, 475)
                    )}`}
                    src={heroImageData?.getHeroImage}
                    alt="gallery-image"
                    className="object-cover "
                  />
                </motion.div>
              </div>
              <div className="absolute w-full bottom-8 py-8 gap-8 flex items-center flex-col">
                <h3 className="font-creattion text-white text-[3rem] md:text-[4.25rem]">
                  Precious + Michael
                </h3>
                <span className="font-neue text-white font-bold md:text-[1.25rem]">
                  ARE GETTING MARRIED
                </span>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full  border-b-4 pb-2  border-black">
          <div className="grid grid-cols-1 py-2 pb-8 lg:grid-cols-2 gap-4 border-b border-b-black">
            <div className="grid grid-rows-4 gap-4">
              <div className="row-span-3 h-full rounded-[18px] border-[#cbc6bf] border-4 lg:border-[8px] py-12 flex justify-center flex-col gap-[1px]">
                <h2 className="text-center text-[3.5rem] leading-[2.5rem] mb-4 font-lovera  text-[#a59b8b] font-bold">
                  Wedding <br />{" "}
                  <span className="tracking-tight leading-[2.5rem] text-[2rem] font-neue">
                    Itinerary
                  </span>
                </h2>
                {eventsPartsWithColors.map((part, idx) => (
                  <div
                    key={idx}
                    className="w-full pt-16 pb-4 px-4 flex flex-col items-center justify-center bg-[#a59b8b] text-white"
                  >
                    <span className="text-[1.5rem] md:text-[2rem] font-bold">
                      {part?.name}
                    </span>
                    <span className="text-[1.25rem] md:text-[1.5rem]  font-[500]">
                      {new Date(part.time).toDateString()}
                    </span>
                    <span className="text-[1.25rem] md:text-[1.5rem] font-[500]">
                      {new Date(part.time).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                    {!!part?.colors?.length && (
                      <div className="w-max mt-[0.88rem] px-8 py-2 flex flex-col gap-1 bg-white rounded-[0.40631rem]">
                        <span className="text-black text-[0.75rem]">
                          Colors of the day
                        </span>
                        <div className="flex items-center justify-center gap-1">
                          {part.colors.map((c) => (
                            <div
                              key={idx}
                              className="inline-block w-4 h-4 rounded-full"
                              style={{
                                backgroundColor: c,
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="row-span-1">
                <div className="rounded-[18px] border-[#cbc6bf] border-4 lg:border-[8px] h-full w-full flex flex-col items-center justify-end gap-[1.57rem]">
                  <h3 className="text-[#A99F8F] font-neue text-[1.25rem] md:text-[1.9rem] font-[600]">
                    OUR ASO-EBI
                  </h3>
                  <p className="text-[#A99F8F] font-neue text-center text-[1.25rem] md:text-[1.9rem] font-[600] max-w-[29rem] px-8">
                    The beautiful attires for our wedding
                  </p>
                  <Link href={"https://tally.so/r/mB1Bq5"} passHref>
                    <a target="_blank" className=" mb-4 justify-self-end">
                      <button className="py-[0.25rem] font-[500] uppercase text-[#A99F8F]  border-solid hover:bg-[#A99F8F]/10 mt-8d px-4 !border-[1.5px] !border-[#A99F8F] rounded-[0.875rem]">
                        GET YOUR ASO-EBI
                      </button>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="grid grid-rows-2 gap-4 min-h-[700px]">
              {albumDetailsData?.getAlbumDetails
                ?.slice(0, 2)
                ?.map((album, idx) => {
                  return (
                    <div
                      key={idx}
                      className="w-full  h-full rounded-[18px] bg-slate-200 relative overflow-hidden"
                    >
                      <motion.div
                        initial={{ scale: 1 }}
                        animate={{
                          scale: 1.1,
                          transition: {
                            delay: 0.3,
                            duration: 0.8,
                            ease: [0.79, 0.14, 0.15, 0.86],
                          },
                        }}
                        className={
                          "relative top-0 bottom-0 right-0  left-0 w-full h-full after:absolute after:w-full after:h-full "
                        }
                      >
                        <Image
                          placeholder="blur"
                          loading="eager"
                          layout="fill"
                          blurDataURL={`data:image/svg+xml;base64,${toBase64(
                            shimmer(700, 475)
                          )}`}
                          src={album?.url!}
                          alt="gallery-image"
                          height={475}
                          className="object-cover "
                        />
                      </motion.div>
                      {idx === 1 && (
                        <div className="flex justify-center inset-x-0 bottom-4  absolute">
                          <Link
                            href={`https://${slug}.${process.env.NEXT_PUBLIC_INAWO_ALBUM_SITE_END}`}
                            passHref
                          >
                            <a target="_blank" className="mt-8 relative">
                              <button className="py-[0.25rem]   text-white  w-max z-10 font-[500] uppercase border-solid hover:bg-white/10 px-4 !border-2 !border-white rounded-[0.875rem]">
                                VIEW ALL PICTURES
                              </button>
                            </a>
                          </Link>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
        <section className="w-full  border-b-4 pb-2  border-black">
          <div className="grid grid-cols-1 py-2 pb-8 lg:grid-cols-2 gap-4 border-b border-b-black">
            {backStoryData?.getBackStory?.map((story, idx) => (
              <div
                key={idx}
                className="flex flex-col gap-[1.56rem] items-center h-max"
              >
                <div
                  key={idx}
                  className="rounded-[18px] relative h-[300px] lg:[500px]  overflow-hidden border-[#cbc6bf] border-4 lg:border-[8px] w-full flex flex-col items-center justify-end gap-[1.57rem]"
                >
                  <motion.div
                    initial={{ scale: 1 }}
                    animate={{
                      scale: 1.1,
                      transition: {
                        delay: 0.3,
                        duration: 0.8,
                        ease: [0.79, 0.14, 0.15, 0.86],
                      },
                    }}
                    className={
                      "relative top-0 bottom-0 right-0  left-0 w-full h-full after:absolute after:w-full after:h-full "
                    }
                  >
                    <Image
                      placeholder="blur"
                      loading="eager"
                      layout="fill"
                      blurDataURL={`data:image/svg+xml;base64,${toBase64(
                        shimmer(700, 475)
                      )}`}
                      src={story?.image!}
                      alt="gallery-image"
                      className="object-cover object-top"
                    />
                  </motion.div>
                </div>
                <h4 className="text-[#2E2E2E] font-bold text-[1.3125rem] tracking-[0.02625rem] font-neue">
                  {story?.role}
                </h4>
                <p className="max-w-[30rem] tex-[0.75rem] md:text-4 text-justify text-[#7C7C7C] font-neue">
                  {truncate(story?.story, {
                    length: 300,
                  })}
                </p>
                <DialogModal
                  trigger={
                    <button className="py-[0.25rem] font-[500] uppercase text-[#A99F8F]  border-solid hover:bg-[#A99F8F]/10 mt-8d px-4 !border-[1.5px] !border-[#A99F8F] rounded-[0.875rem]">
                      Continue Reading
                    </button>
                  }
                >
                  <BackstoryModal
                    story={story?.story ?? ""}
                    role={story?.role ?? ""}
                  />
                </DialogModal>
              </div>
            ))}
          </div>
        </section>

        <section className="w-full">
          <div className="rounded-[18px] bg-slate-200  h-[300px]  relative overflow-hidden">
            <motion.div
              initial={{ scale: 1 }}
              animate={{
                scale: 1.1,
                transition: {
                  delay: 0.3,
                  duration: 0.8,
                  ease: [0.79, 0.14, 0.15, 0.86],
                },
              }}
              className={
                "relative top-0 bottom-0 right-0 grayscale  left-0 w-full h-full after:absolute after:w-full after:h-full after:bg-black/60"
              }
            >
              <Image
                placeholder="blur"
                loading="eager"
                layout="fill"
                blurDataURL={`data:image/svg+xml;base64,${toBase64(
                  shimmer(700, 475)
                )}`}
                src={image}
                alt="gallery-image"
                className="object-cover "
              />
            </motion.div>
            <div className="inset-0 flex  text-white flex-col items-center justify-center absolute">
              <span className="font-bold font-lovera text-[1.25rem] md:text-[1.5rem]">
                SHARE THE
              </span>
              <span className="font-lovera text-[2rem] md:text-[3rem] font-bold leading-[2.5rem]">
                Moment
              </span>
              <span className="font-cursive italic font-[700]">
                {eventBySlugData?.eventBySlug?.hash_tag}
              </span>
              <div className="flex justify-center inset-x-0 bottom-4  absolute">
                <Link href={`#`} passHref>
                  <a target="_blank" className="mt-8 relative">
                    <button className="py-[0.25rem]   text-white  w-max z-10 font-[500] uppercase border-solid hover:bg-white/10 px-4 !border-2 !border-white rounded-[0.875rem]">
                      Add PICTURES
                    </button>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full">
          <p className="text-center text-[#7E6F5D] font-creattion text-[4rem]">
            made with love by{" "}
            <Link href={process.env.NEXT_PUBLIC_API_LANDING_URL!} passHref>
              <a target="_blank" className="hover:underline">
                Inawo
              </a>
            </Link>
          </p>
        </section>
      </div>
    </ClientOnly>
  );
};

export default Home;
