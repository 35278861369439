import * as React from "react";
import Icon from "../../Icon";
import styles from "./styles.module.scss";
import Boop from "src/components/Boop";
import Dialog from "src/components/Dialog";
import { MemoMessageModal as MessageModal } from "src/components/modals/MessageModal";
import { MemoCashGiftModal as CashGiftModal } from "src/components/modals/CashGiftModal";
import Invitation from "src/components/modals/Invitation";
import { MemoLivehallModal as LivehallModal } from "src/components/modals/LivehallModal";
import { IconType } from "@src/utils/icon";
import { motion } from "framer-motion";
import {
  eventBySlug,
  Currency,
  getEventStreamers,
  getForeignAccountDetails,
  getLiveSchedule,
} from "@src/@types/api.d";
import { getCookie } from "cookies-next";
import { useQuery } from "@apollo/client";
import { GetForeignWalletDetails } from "@src/graphql/queries";
import toast from "react-hot-toast";
import LiveSchedules from "../../LiveSchedules";
import Link from "next/link";

type Props = {
  eventId: number;
  hasWallet?: boolean;
  slugData: eventBySlug | undefined;
  loading?: boolean;
  getEventStreamersData: getEventStreamers;
  liveSchedules: getLiveSchedule;
};

type deckType = {
  icon: IconType;
  title: string;
  modal?: JSX.Element;
  open?: boolean;
  fillScreen?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  link?: string;
};

const Floater: React.FC<Props> = ({
  getEventStreamersData,
  eventId,
  slugData,
  liveSchedules,
  hasWallet,
}) => {
  const [openInvitation, setOpenInvitation] = React.useState<boolean>(false);
  const [openWishlist, setOpenWishlist] = React.useState<boolean>(false);
  const [openCashGift, setOpenCashGift] = React.useState<boolean>(false);
  const [openMessage, setOpenMessage] = React.useState<boolean>(false);
  // const [openMoneyBag, setMoneyBag] = React.useState<boolean>(false);
  const [openStream, setOpenStream] = React.useState<boolean>(false);
  // const [openStore, setOpenStore] = React.useState<boolean>(false);
  const [accessToken, setAccessToken] = React.useState<string>("");

  React.useEffect(() => {
    setAccessToken(getCookie("tsl") as string);
  }, []);

  const { data: foreignWalletDetails, loading: _foreignDetailsLoading } =
    useQuery<getForeignAccountDetails>(GetForeignWalletDetails, {
      skip: !eventId,
      variables: {
        eventId: !!eventId ? eventId : 0,
      },
      fetchPolicy: "network-only",
      onError(error) {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      },
    });

  const foreignDetails = React.useMemo(() => {
    const res = foreignWalletDetails?.getForeignAccountDetails.find(
      (item) => item?.currency === Currency.gpb || Currency.usd
    );
    return res;
  }, [foreignWalletDetails]);

  const deck: deckType[] = [
    {
      icon: "invitation",
      title: "RSVP",
      modal: (
        <Invitation
          name={slugData?.eventBySlug?.name ?? ""}
          date={slugData?.eventBySlug?.date ?? ""}
          eventId={eventId}
          setOpen={setOpenInvitation}
        />
      ),
      open: openInvitation,
      setOpen: setOpenInvitation,
      fillScreen: false,
    },
    {
      icon: "cashGift",
      title: "Cash gift",
      modal: (
        <CashGiftModal
          foreignDetails={foreignDetails}
          eventId={eventId}
          setOpen={setOpenCashGift}
        />
      ),
      open: openCashGift,
      setOpen: setOpenCashGift,
      fillScreen: false,
    },
    {
      icon: "message",
      title: "Message",
      modal: (
        <MessageModal
          name={slugData?.eventBySlug?.name ?? ""}
          eventId={eventId}
          setOpen={setOpenMessage}
        />
      ),
      open: openMessage,
      setOpen: setOpenMessage,
      fillScreen: false,
    },
    {
      icon: "stream",
      title: "Live Stream",
      modal:
        // TODO: we'll later change *true* to a logic that checks live hall kind
        true ? (
          <LiveSchedules schedules={liveSchedules} />
        ) : (
          <LivehallModal
            src={
              !!getEventStreamersData.getEventStreamers &&
              getEventStreamersData.getEventStreamers!.length > 0
                ? getEventStreamersData.getEventStreamers![0]?.playback_url
                : ""
            }
            isLive={
              !!getEventStreamersData.getEventStreamers &&
              getEventStreamersData.getEventStreamers!.length > 0
                ? !!getEventStreamersData.getEventStreamers![0]?.is_streaming
                : false
            }
            eventId={eventId}
            setOpen={setOpenStream}
          />
        ),
      open: openStream,
      setOpen: setOpenStream,
      fillScreen: true,
    },
    {
      icon: "aso-ebi",
      title: "Aso-Ebi",
      link: "https://tally.so/r/w2PVBV",
    },
  ];

  return (
    <motion.div className={styles["floater"]}>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        whileInView={{
          opacity: 1,
          scale: 1,
          transition: {
            delay: 1.4,
            duration: 0.5,
            ease: [0.79, 0.14, -0.15, 0.86],
          },
        }}
        className={styles["floater-container"]}
      >
        {deck
          // remove atuh features if the user isn't authenticated
          .filter((item) =>
            !!accessToken
              ? item
              : !["Invitation", "Store", "Message"].includes(item.title)
          )
          .filter((item) =>
            liveSchedules?.getLiveSchedule?.recordCount ||
            !!getEventStreamersData.getEventStreamers?.[0]?.event
              ?.event_preference?.live_hall
              ? item
              : !["Live Stream"].includes(item.title)
          )
          .filter((item) =>
            !liveSchedules?.getLiveSchedule?.recordCount ||
            (!!getEventStreamersData.getEventStreamers?.[0]?.event
              ?.event_preference?.private &&
              !accessToken)
              ? !["Live Stream"].includes(item.title)
              : item
          )
          .filter((item) =>
            liveSchedules?.getLiveSchedule?.recordCount
              ? item
              : !["Live Stream"].includes(item.title)
          )
          .map((item) => {
            return item?.link ? (
              <Link key={item?.title} passHref href={item?.link}>
                <a target="_blank">
                  <Boop y={2}>
                    <div className={styles["floater-icon"]}>
                      <div className={styles["floater-icon-box"]}>
                        <Icon iconName={item.icon} />
                      </div>
                      <p className={styles["floater-paragraph"]}>
                        {item.title}
                      </p>
                    </div>
                  </Boop>
                </a>
              </Link>
            ) : (
              <Dialog
                key={item.title}
                fillScreen={item.fillScreen}
                trigger={
                  <Boop y={2}>
                    <div className={styles["floater-icon"]}>
                      <div className={styles["floater-icon-box"]}>
                        <Icon iconName={item.icon} />
                      </div>
                      <p className={styles["floater-paragraph"]}>
                        {item.title}
                      </p>
                    </div>
                  </Boop>
                }
                open={item.open}
                onOpenChange={item.setOpen}
              >
                {item.modal}
              </Dialog>
            );
          })}
      </motion.div>
    </motion.div>
  );
};

export default Floater;
