import * as React from "react";
import Icon from "../Icon";
import styles from "./parts.module.scss";
import { motion } from "framer-motion";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import { eventParts, getLiveSchedule } from "@src/@types/api.d";
import { getCookie } from "cookies-next";
import DialogModal from "../Dialog";
import LiveSchedules from "../LiveSchedules";

export type IParts = {
  eventId: number;
  eventPartsData: eventParts;
  liveSchedules?: getLiveSchedule;
};

const Parts: React.FC<IParts> = ({
  eventId,
  eventPartsData,
  liveSchedules,
}) => {
  const currentAccessToken = getCookie("tsl") as string;

  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setShow(true);
  }, []);

  const addOneDay = (dateTime: string) => {
    var date = new Date(dateTime);
    date.setHours(date.getHours() + 24);
    var isoDate = date.toISOString();
    return isoDate;
  };

  return !!eventPartsData?.eventParts &&
    eventPartsData?.eventParts?.length > 0 ? (
    <div className={styles["home__event-types-container"]}>
      {!!eventPartsData.eventParts && eventPartsData.eventParts.length === 0 ? (
        <></>
      ) : (
        <motion.div
          initial={{ opacity: 1 }}
          whileInView={{
            opacity: 1,
            transition: {
              delayChildren: 0.5,
              staggerChildren: 0.5,
            },
          }}
          className={styles["home__event-types"]}
        >
          {eventPartsData?.eventParts?.map((item, idx) => (
            <motion.div
              initial={{ opacity: 0, y: -15 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.2,
                  duration: 1,
                  ease: [0.79, 0.14, 0.15, 0.86],
                },
              }}
              key={`home action ${idx}`}
              className={styles["home__event-types-box"]}
            >
              <div className={styles["home__event-types-icon"]}>
                <Icon iconName="roses" />
              </div>
              <h1 className={styles["home__event-types-heading"]}>
                {item.name}
              </h1>
              <p className={styles["home__event-types-date"]}>
                {new Date(item.time).toDateString()}
              </p>
              <p className={styles["home__event-types-time"]}>
                {new Date(item.time).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </p>
              {show && currentAccessToken ? (
                <p className={styles["home__event-types-time"]}>
                  {item?.address}
                </p>
              ) : (
                <></>
              )}
              {liveSchedules?.getLiveSchedule?.data?.find(
                (i) => i?.event_part?.id === item?.id
              ) ? (
                <DialogModal
                  fillScreen={true}
                  trigger={
                    <button className="bg-inawo-gold-300 rounded-[0.125rem] h-[2.75rem] px-[1.5rem] flex items-center gap-[0.5rem] border-solid !border border-black mb-[0.75rem] hover:opacity-90 ease-in-out duration-300 transition-opacity">
                      <Icon iconName="videoCamera" />
                      <span className="">Watch Live</span>
                    </button>
                  }
                >
                  <LiveSchedules schedules={liveSchedules} />
                </DialogModal>
              ) : (
                <></>
              )}
              {currentAccessToken && (
                <AddToCalendar
                  event={{
                    name: item.name,
                    details: null,
                    location: item?.address,
                    startsAt: item?.time,
                    endsAt: addOneDay(item?.time),
                  }}
                >
                  <span>Save Date</span>
                </AddToCalendar>
              )}
            </motion.div>
          ))}
        </motion.div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default Parts;
