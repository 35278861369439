import * as Dialog from "@radix-ui/react-dialog";
import { ReactNode, Dispatch } from "react";
import Icon from "../Icon";
import styles from "./dialog.module.scss";

interface DiaglogProps {
  trigger: ReactNode;
  title?: string | ReactNode;
  description?: string | ReactNode;
  children: ReactNode;
  fillScreen?: boolean;
  open?: boolean;
  onOpenChange?: Dispatch<React.SetStateAction<boolean>>;
}

const DialogModal = ({
  trigger,
  title,
  description,
  children,
  ...props
}: DiaglogProps) => (
  <Dialog.Root {...props}>
    <Dialog.Trigger className={styles["dialog__trigger"]}>
      {trigger}
    </Dialog.Trigger>

    <Dialog.Portal>
      <Dialog.Overlay className={styles["dialog__overlay"]}>
        <Dialog.Content
          className={`${
            props.fillScreen
              ? styles["dialog__fill"]
              : styles["dialog__content"]
          }`}
        >
          <Dialog.Close
            className={`${
              props.fillScreen
                ? styles["dialog__close--fill"]
                : styles["dialog__close"]
            }`}
          >
            <Icon iconName={"close2"} />
          </Dialog.Close>
          {title && <Dialog.Title>{title}</Dialog.Title>}
          {description && <Dialog.Description></Dialog.Description>}
          {children}
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  </Dialog.Root>
);

export default DialogModal;
