import Button from "@src/components/Button";
import React from "react";
import image1 from "../../../../public/images/image13.jpeg";
import image2 from "../../../../public/images/image12.jpeg";
import { shimmer, toBase64 } from "@src/utils/shimmer";
import Image from "next/image";
import Link from "next/link";

const AddPhotos = () => {
  return (
    <div className="container relative py-[3rem] md:py-[7.5rem] flex flex-col md:flex-row md:items-end gap-x-[7.75rem] gap-y-[4rem] text-inawo-black-900/80">
      <div className="relative w-full max-w-[30rem] h-[29.3rem]">
        <div className="absolute w-[18.5rem] h-[25.3rem] bottom-0 left-0 mb-[4rem]">
          <Image
            placeholder="blur"
            loading="eager"
            layout="fill"
            blurDataURL={`data:image/svg+xml;base64,${toBase64(
              shimmer(700, 475)
            )}`}
            src={image1}
            alt="gallery-image"
            className="object-cover"
          />
        </div>
        <div className="absolute w-[14.4rem] h-[18.875rem] bottom-0 right-0">
          <Image
            placeholder="blur"
            loading="eager"
            layout="fill"
            blurDataURL={`data:image/svg+xml;base64,${toBase64(
              shimmer(700, 475)
            )}`}
            src={image2}
            alt="gallery-image"
            className="object-cover"
          />
        </div>
      </div>
      <div className="flex flex-col justify-end ">
        <h4 className="text-[1.5rem] md:text-[2.25rem] font-[700] leading-[150%] uppercase font-cinzel mb-4">
          CREATING memories with snapshots
        </h4>
        <p className="text-[1rem] md:text-[1.25rem] font-[300] leading-[150%] font-temp mb-8">
          Let us witness the day through your lens! We have set up a virtual
          gallery where you can share pictures of beautiful moments you captured
          with us.
        </p>
        <Link href={"https://photos.app.goo.gl/GcTNCnqVr6JsqweG7"} passHref>
          <a target="_blank">
            <Button label="Add your photo" design="template-2" type="button">
              Add your photo
            </Button>
          </a>
        </Link>
      </div>
    </div>
  );
};

export default AddPhotos;
