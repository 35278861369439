import * as React from "react";
import Marquee from "react-marquee-slider";
import styles from "./marquee.module.scss";
import { MemoMessageModal as MessageModal } from "../modals/MessageModal";
import Dialog from "../Dialog";
import { useQuery } from "@apollo/client";
import {
  getApprovedMessages,
  getApprovedMessagesVariables,
  eventBySlug,
} from "@src/@types/api.d";
import { GetApprovedMessages } from "@src/graphql/queries";
import { getCookie } from "cookies-next";
import Icon from "@src/components/Icon";

type Props = {
  eventId: number;
  slugData: eventBySlug | undefined;
};

const MarqueeComp: React.FC<Props> = ({ slugData, eventId }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);
  const currentAccessToken = getCookie("tsl") as string;

  React.useEffect(() => {
    setShow(true);
  }, []);

  const {
    data: getApprovedMessagesData,
    // loading: getApprovedMessagesLoading,
    // error: getApprovedMessagesError,
  } = useQuery<getApprovedMessages, getApprovedMessagesVariables>(
    GetApprovedMessages,
    {
      skip: !eventId,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        eventId,
      },
      pollInterval: 1000 * 60,
    }
  );

  return !!getApprovedMessagesData?.getApprovedMessages &&
    getApprovedMessagesData?.getApprovedMessages.length > 0 ? (
    <div className={styles["home__marquee"]}>
      <div style={{ height: "250px", maxWidth: "100vw", width: "100%" }}>
        <Marquee
          velocity={40}
          direction="rtl"
          scatterRandomly={false}
          resetAfterTries={200}
          onInit={() => null}
          onFinish={() => null}
        >
          {getApprovedMessagesData.getApprovedMessages
            .filter((_, i) => i % 2 === 0)
            .map((item, index) => (
              <div
                key={`quotes-${index}`}
                className={styles["home__special-note-box"]}
              >
                <div
                  className={styles["home__special-note-box-circle-container"]}
                >
                  <Icon iconName="pin" />
                </div>
                <p className={styles["home__special-note-text"]}>
                  {item?.content ?? ""}
                </p>
              </div>
            ))}
        </Marquee>
      </div>
      {getApprovedMessagesData.getApprovedMessages.length > 1 && (
        <div style={{ height: "250px", maxWidth: "100vw", width: "100%" }}>
          <Marquee
            velocity={40}
            direction="rtl"
            scatterRandomly={false}
            resetAfterTries={200}
            onInit={() => null}
            onFinish={() => null}
          >
            {getApprovedMessagesData.getApprovedMessages
              .filter((_, i) => i % 2 !== 0)
              .map((item, index) => (
                <div
                  key={`quotes-2-${index}`}
                  className={styles["home__special-note-box"]}
                >
                  <div
                    className={
                      styles["home__special-note-box-circle-container"]
                    }
                  >
                    <Icon iconName="pin" />
                  </div>

                  <p className={styles["home__special-note-text"]}>
                    {item?.content ?? ""}
                  </p>
                </div>
              ))}
          </Marquee>
        </div>
      )}
      {show && !!currentAccessToken && (
        <div>
          <Dialog
            trigger={
              <div className={styles["home__special-note__btn"]}>
                Drop a special note
              </div>
            }
            open={open}
            onOpenChange={setOpen}
          >
            <MessageModal
              name={slugData?.eventBySlug?.name ?? ""}
              eventId={eventId}
              setOpen={setOpen}
            />
          </Dialog>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default MarqueeComp;
