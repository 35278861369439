import * as React from "react";
import Icon from "../Icon";
import styles from "./vendors.module.scss";
import { eventVendor } from "@src/@types/api.d";

type Props = {
  eventId: number;
  eventVendorData: eventVendor;
};

const Vendors: React.FC<Props> = ({ eventId, eventVendorData }) => {
  return !!eventVendorData?.eventVendor &&
    eventVendorData?.eventVendor.length > 0 ? (
    <div className={styles["home__social"]}>
      <div className={styles["home__social-container"]}>
        <div className={styles["home__social-container-row"]}>
          {eventVendorData?.eventVendor.map((vendor, index) => (
            <div
              key={`vendors-home-page-${index}`}
              className={styles["home__social-box"]}
            >
              <h1 className={styles["home__social-heading"]}>{vendor?.name}</h1>
              <p className={styles["home__social-sub-heading"]}>
                {vendor?.role}
              </p>
              <p className={styles["home__social-sub-heading"]}>
                {vendor?.email}
              </p>
              <div className={styles["home__social-icons"]}>
                {/* <div className={styles["home__social-icon"]}>
									<Icon iconName="facebook" />
								</div>
								<div className={styles["home__social-icon"]}>
									<Icon iconName="twitter" />
								</div> */}
                {!!vendor?.instagram_handle && (
                  <a
                    target="_blank"
                    href={
                      vendor?.instagram_handle.includes("@")
                        ? `https://www.instagram.com/${
                            vendor?.instagram_handle.split("@")[1]
                          }/`
                        : `https://www.instagram.com/${vendor?.instagram_handle}/`
                    }
                    rel="noopener noreferrer"
                    className={styles["home__social-icon"]}
                  >
                    <Icon iconName="instagram" />
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Vendors;
