import Logo from "@assets/icons/logo.svg";
import Naira from "@assets/icons/naira.svg";
import Download2 from "@assets/icons/download-2.svg";
import Link from "@assets/icons/link.svg";
import Close2 from "@assets/icons/close2.svg";
import Location from "@assets/icons/location.svg";
import Phone from "@assets/icons/phone.svg";
import ArrowLeft from "@assets/icons/arrowLeft.svg";
import LeftLeaf from "@assets/icons/leftLeaf.svg";
import Notifications from "@assets/icons/notifications.svg";
import LeftLeafMax from "@assets/icons/leftLeafMax.svg";
import RightLeafMax from "@assets/icons/rightLeafMax.svg";
import LeftQuotesFlower from "@assets/icons/leftQuotesFlower.svg";
import RightQuotesFlower from "@assets/icons/rightQuotesFlower.svg";
import Wine from "@assets/icons/wine.svg";
import Facebook from "@assets/icons/facebook.svg";
import Twitter from "@assets/icons/twitter.svg";
import Instagram from "@assets/icons/instagram.svg";
import ScheduleIcon from "@assets/icons/scheduleIcon.svg";
import SmsMessage from "@assets/icons/smsMessage.svg";
import Store from "@assets/icons/store.svg";
import NewGifts from "@assets/icons/newGifts.svg";
import Book from "@assets/icons/book.svg";
import LiveEvent from "@assets/icons/liveEvent.svg";
import Download from "@assets/icons/download.svg";
import PhysicalGift from "@assets/icons/physicalGift.svg";
import Money from "@assets/icons/money.svg";
import GalleryFlower from "@assets/icons/galleryFlower.svg";
import CashGift from "@assets/icons/cash-gift.svg";
import Bell from "@assets/icons/bell.svg";
import Invitation from "@assets/icons/invitation.svg";
import Message from "@assets/icons/message.svg";
import Wishlist from "@assets/icons/wishlist.svg";
import Roses from "@assets/icons/roses.svg";
import Ring from "@assets/icons/love-ring.svg";
import MessageSent from "@assets/icons/message-sent.svg";
import Approved from "@assets/icons/approved.svg";
import Pin from "@assets/icons/pin.svg";
import Paystack from "@assets/icons/paystack.svg";
import Monnify from "@assets/icons/monnify.svg";
import NairaGiftOption from "@assets/icons/nairaOptionIcon.svg";
import DollarGiftOption from "@assets/icons/dollarOptionIcon.svg";
import CopyIcon from "@assets/icons/copyIcon.svg";
import Stream from "@assets/icons/stream.svg";
import NoVideo from "@assets/icons/no-video.svg";
import VideoCamera from "@assets/icons/videoCamera.svg";
import AsoEbi from "@assets/icons/asoEbi.svg";

export type IconType =
  | "noVideo"
  | "stream"
  | "naira"
  | "logo"
  | "download2"
  | "link"
  | "location"
  | "arrowLeft"
  | "phone"
  | "roses"
  | "close2"
  | "ring"
  | "leftLeaf"
  | "notifications"
  | "approved"
  | "messageSent"
  | "leftLeafMax"
  | "rightLeafMax"
  | "leftQuotesFlower"
  | "rightQuotesFlower"
  | "wine"
  | "facebook"
  | "twitter"
  | "instagram"
  | "scheduleIcon"
  | "book"
  | "newGifts"
  | "smsMessage"
  | "liveEvent"
  | "download"
  | "money"
  | "physicalGift"
  | "store"
  | "cashGift"
  | "bell"
  | "invitation"
  | "message"
  | "pin"
  | "paystack"
  | "monnify"
  | "wishlist"
  | "galleryFlower"
  | "nairaGift"
  | "dollarGift"
  | "copyIcon"
  | "videoCamera"
  | "aso-ebi";

export const icons: Record<IconType, JSX.Element> = {
  stream: <Stream />,
  noVideo: <NoVideo />,
  naira: <Naira />,
  logo: <Logo />,
  download2: <Download2 />,
  link: <Link />,
  location: <Location />,
  phone: <Phone />,
  arrowLeft: <ArrowLeft />,
  roses: <Roses />,
  ring: <Ring />,
  close2: <Close2 />,
  leftLeaf: <LeftLeaf />,
  notifications: <Notifications />,
  messageSent: <MessageSent />,
  approved: <Approved />,
  leftLeafMax: <LeftLeafMax />,
  rightLeafMax: <RightLeafMax />,
  leftQuotesFlower: <LeftQuotesFlower />,
  rightQuotesFlower: <RightQuotesFlower />,
  wine: <Wine />,
  pin: <Pin />,
  facebook: <Facebook />,
  instagram: <Instagram />,
  twitter: <Twitter />,
  scheduleIcon: <ScheduleIcon />,
  store: <Store />,
  newGifts: <NewGifts />,
  liveEvent: <LiveEvent />,
  smsMessage: <SmsMessage />,
  book: <Book />,
  paystack: <Paystack />,
  monnify: <Monnify />,
  download: <Download />,
  physicalGift: <PhysicalGift />,
  money: <Money />,
  galleryFlower: <GalleryFlower />,
  cashGift: <CashGift />,
  bell: <Bell />,
  invitation: <Invitation />,
  message: <Message />,
  wishlist: <Wishlist />,
  nairaGift: <NairaGiftOption />,
  dollarGift: <DollarGiftOption />,
  copyIcon: <CopyIcon />,
  videoCamera: <VideoCamera />,
  "aso-ebi": <AsoEbi />,
};
