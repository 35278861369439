import * as React from "react";
import wishlist from "./wishlist.module.scss";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import Dialog from "@src/components/Dialog";
import Item from "@src/components/modals/ItemModal";
import { useQuery } from "@apollo/client";
import { getWishListItems, getWishListItemsVariables } from "@src/@types/api.d";
import { GetWishListItems } from "@src/graphql/queries";
import Icon from "@src/components/Icon";

interface WishlistProps {
  eventId: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Wishlist = ({ eventId, setOpen }: WishlistProps) => {
  const [openItem, setOpenItem] = React.useState<boolean>(false);

  const { data: getWishListItemsData, loading: getWishListItemsLoading } =
    useQuery<getWishListItems, getWishListItemsVariables>(GetWishListItems, {
      skip: !eventId,
      variables: {
        eventId,
        pageSize: 50,
      },
    });

  return (
    <div className={wishlist["wishlist--content"]}>
      <div className={wishlist["wishlist--content__top"]}>
        <h1>Wedding Wishlist</h1>
      </div>

      <ScrollArea.Root className={wishlist["wishlist--content__scroll"]}>
        <ScrollArea.Viewport className={wishlist["wishlist--content__bottom"]}>
          {getWishListItemsLoading ? (
            <>Loading...</>
          ) : !!getWishListItemsData?.getWishListItems &&
            getWishListItemsData?.getWishListItems.length > 0 ? (
            getWishListItemsData?.getWishListItems?.map(
              (item, index: number) => (
                <div
                  key={`item-list-${index}`}
                  className={wishlist["wishlist--content__item__outer"]}
                >
                  <div className={wishlist["wishlist--content__item"]}>
                    <div className={wishlist["wishlist--content__item__left"]}>
                      <p>{item?.name ?? ""}</p>
                      <h2>
                        {!!item && !!item?.amount
                          ? `N${(item?.amount).toLocaleString()}`
                          : "Not available"}
                      </h2>
                    </div>
                  </div>
                  <div
                    className={wishlist["wishlist--content__item__download"]}
                  >
                    <div className={wishlist["wishlist--content__item__right"]}>
                      <Dialog
                        trigger={
                          <div className={wishlist["wishlist--content__btn"]}>
                            Send Money
                          </div>
                        }
                      >
                        <Item
                          title={item?.name ?? ""}
                          id={item?.uuid ?? 0}
                          amount={!!item?.amount ? item?.amount : 0}
                          eventId={eventId}
                          setOpen={setOpen}
                          setOpenItem={setOpenItem}
                        />
                      </Dialog>
                    </div>
                    {!!item?.image && item.image !== "not set" && (
                      <a
                        target="_blank"
                        rel="noreferrer noopenner"
                        href={item.image}
                        className={wishlist["wishlist--content__item__link"]}
                      >
                        <Icon iconName="link" />
                      </a>
                    )}
                  </div>
                </div>
              )
            )
          ) : (
            <>No Items</>
          )}
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar
          className={wishlist["wishlist--content__scroll__bar"]}
          orientation="vertical"
        >
          <ScrollArea.Thumb
            className={wishlist["wishlist--content__scroll__thumb"]}
          />
        </ScrollArea.Scrollbar>
        <ScrollArea.Scrollbar
          className={wishlist["wishlist--content__scroll__bar"]}
          orientation="horizontal"
        >
          <ScrollArea.Thumb
            className={wishlist["wishlist--content__scroll__thumb"]}
          />
        </ScrollArea.Scrollbar>
        {/* <ScrollArea.Corner /> */}
      </ScrollArea.Root>
    </div>
  );
};

export const MemoWishlist = React.memo(Wishlist);
