import styles from "./button.module.scss";

interface ButtonProps {
  children: React.ReactNode;
  label: string;
  loading?: boolean;
  handleClick?: () => void;
  type: "button" | "reset" | "submit";
  disabled?: boolean;
  design?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "other"
    | "another"
    | "template-2";
}

const Button: React.FC<ButtonProps> = ({
  children,
  type,
  label,
  handleClick,
  disabled,
  loading = false,
  design = "primary",
}) => {
  return (
    <button
      onClick={handleClick}
      aria-label={label}
      type={type}
      className={`${
        design === "primary"
          ? styles["button__primary"]
          : design === "secondary"
          ? styles["button__secondary"]
          : design === "tertiary"
          ? styles["button__tertiary"]
          : design === "other"
          ? styles["button__other"]
          : design === "another"
          ? styles["button__another"]
          : design === "template-2"
          ? "w-max !text-white bg-inawo-blue-100 hover:bg-inawo-blue-100/90 transition-colors ease-in-out duration-300  p-4 rounded-[0.25rem] "
          : ""
      }`}
      disabled={disabled || loading}
    >
      <span>{children}</span>
    </button>
  );
};

export default Button;
