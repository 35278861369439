import { shimmer, toBase64 } from "@src/utils/shimmer";
import Image from "next/image";
import React from "react";
import flower from "../../../../public/images/pinkFlowerImage.png";
import { eventBySlug } from "@src/@types/api.d";

interface IFooter {
  slug: eventBySlug | undefined;
}

const Footer = ({ slug }: IFooter) => {
  return (
    <footer className="w-full min-h-[14.69rem] overflow-hidden bg-inawo-pink-200 grid relative place-items-center">
      <h3 className="font-dacing text-[2.5rem] md:text-[4rem] leading-[120%] tracking-[0.2rem] text-white">
        {slug?.eventBySlug?.name}
      </h3>
      <div className="absolute bottom-0 left-0 -ml-[2.5rem] -mb-[2rem] w-[8rem] md:w-[11rem] h-[8rem] md:h-[11rem] -rotate-[7.416deg]">
        <Image
          placeholder="blur"
          loading="eager"
          layout="fill"
          blurDataURL={`data:image/svg+xml;base64,${toBase64(
            shimmer(700, 475)
          )}`}
          src={flower}
          alt="gallery-image"
          className="object-contain"
        />
      </div>
      <div className="absolute bottom-0 -mr-[2rem] -mb-[2rem] right-0 w-[8rem] md:w-[11rem] h-[8rem] md:h-[11rem] -rotate-[7.416deg]">
        <Image
          placeholder="blur"
          loading="eager"
          layout="fill"
          blurDataURL={`data:image/svg+xml;base64,${toBase64(
            shimmer(700, 475)
          )}`}
          src={flower}
          alt="gallery-image"
          className="object-cover transform -scale-x-100"
        />
      </div>
    </footer>
  );
};

export default Footer;
