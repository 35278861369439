import styles from "./styles.module.scss";
import Icon from "@src/components/Icon";

function Footer() {
  return (
    <div className={styles["footer"]}>
      <footer className={styles["footer__footer"]}>
        Made{" "}
        <a href="https://app.inawo.com/" target="_blank" rel="noreferrer">
          <Icon iconName="ring" />
        </a>{" "}
        by{" "}
        <a href="https://app.inawo.com/" target="_blank" rel="noreferrer">
          Inawo
        </a>
      </footer>
    </div>
  );
}

export default Footer;
