import Button from "@src/components/Button";
import React from "react";
import { motion } from "framer-motion";
import Image from "next/image";
import { shimmer, toBase64 } from "@src/utils/shimmer";
import logo from "../../../../public/images/template2PilotLogoDark.png";
import Link from "next/link";

const AsoEbi = () => {
  return (
    <div className="w-full h-max min-h-[35.75rem] bg-inawo-pink-300 ">
      <div className="w-full h-full container py-[3rem] md:py-[7.5rem] flex flex-col gap-x-[6rem] md:flex-row gap-y-[4rem]">
        <motion.div
          initial={{ scale: 1 }}
          animate={{
            scale: 1.1,
            transition: {
              delay: 0.3,
              duration: 0.8,
              ease: [0.79, 0.14, 0.15, 0.86],
            },
          }}
          className="relative w-full h-full flex-grow min-h-[20.75rem]"
        >
          <Image
            placeholder="blur"
            loading="eager"
            layout="fill"
            blurDataURL={`data:image/svg+xml;base64,${toBase64(
              shimmer(700, 475)
            )}`}
            src={logo}
            alt="gallery-image"
            className="object-contain object-bottom"
          />
        </motion.div>
        <div className="flex flex-col justify-end items-center md:items-start w-full max-w-[37.5rem] text-inawo-black-900/80">
          <h5 className="text-center md:text-start font-cinzel mb-4 text-[1.5rem] md:text-[2.25rem] leading-[100%] uppercase">
            Attire ~ our aso-ebi
          </h5>
          <p className="text-center md:text-start mb-8 font-temp font-[300] leading-[140%] ">
            To make the day more special, we have aso-ebi for you. Aso-Ebi
            represents more than just a dress code; it's a symbol of unity and
            togetherness. By donning these beautiful attires, we hope to create
            a visual tapestry of love and connection on our special day.
          </p>
          <Link passHref href={"https://tally.so/r/w2PVBV"}>
            <a target="_blank">
              <Button label="Get aso-ebi" type="button" design="template-2">
                Get aso-ebi
              </Button>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AsoEbi;
