import React from "react";
import { useWatch, useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import InputPhone from "@src/components/InputPhone";
import Button from "@src/components/Button";
import cashGift from "../cashGift.module.scss";
import toast from "react-hot-toast";
import { useMonnifyPayment } from "react-monnify";
import { usePaystackPayment } from "react-paystack";
import { PayForCashGiftV1 } from "@src/graphql/mutations";
import {
  Currency,
  payForCashGiftV1,
  payForCashGiftV1Variables,
  paymentProcessor,
} from "@src/@types/api.d";
import { useMutation } from "@apollo/client";
import { getCookie } from "cookies-next";
import Icon from "@src/components/Icon";
import { formatMoney } from "@src/utils/general";

interface Values {
  fullName: string;
  email: string;
  amount: string | number;
}

const GeneralFormSchema = Yup.object().shape({
  fullName: Yup.string().required("Full name is required"),
  email: Yup.string().email().required("Email is required"),
  amount: Yup.string().required("An amount is required"),
});

interface CashGiftModalProps {
  eventId: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CashGiftPayment = ({ eventId, setOpen }: CashGiftModalProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm<Values>({
    mode: "onChange",
    defaultValues: {
      fullName: "",
      email: "",
      amount: "",
    },
    resolver: yupResolver(GeneralFormSchema),
  });

  const amount = useWatch({ control, name: "amount" });
  const fullName = useWatch({ control, name: "fullName" });
  const email = useWatch({ control, name: "email" });
  const [phoneNumberValue, setPhoneNumberValue] = React.useState<string>("");
  const userId = getCookie("usI") as string;

  const [PayForCashGiftFn] = useMutation<
    payForCashGiftV1,
    payForCashGiftV1Variables
  >(PayForCashGiftV1, {
    onError(error) {
      if (error.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      }
    },
  });

  const paystackCharge = React.useMemo(() => {
    const preTotal = parseInt(`${amount}`);
    const percentCharge = 0.015 * preTotal;
    const fullCharge = percentCharge + 100;
    if (preTotal < 2500) {
      return { charge: percentCharge };
    } else if (fullCharge > 2000) {
      return { charge: 2000, with100: true };
    } else {
      return { charge: fullCharge, with100: true };
    }
  }, [amount]);

  const finalAmount = React.useMemo(() => {
    const paystackFeeRate = 0.015;
    let paystackFixedFee = 0;
    const feeCap = 2000;
    if (Number(amount) >= 2500) {
      paystackFixedFee = 100.0;
    }
    const applicableFee = paystackFeeRate * Number(amount) + paystackFixedFee;
    const final =
      applicableFee > feeCap
        ? Number(amount) + feeCap
        : (Number(amount) + paystackFixedFee) / (1 - paystackFeeRate) + 0.01;
    const res = parseFloat(final.toFixed(2));
    return res;
  }, [amount, paystackCharge?.charge]);

  let monnifyConfigData = {
    apiKey: process.env.NEXT_PUBLIC_MONNIFY_API_ENV!,
    reference: new Date().getTime().toString(),
    amount: Number(amount),
    currency: "NGN",
    paymentMethods: ["CARD", "ACCOUNT_TRANSFER"],
    customerFullName: fullName,
    customerEmail: email,
    contractCode: process.env.NEXT_PUBLIC_MONNIFY_CONTRACT_CODE!,
    customerMobileNumber: "phoneNumberValue",
    isTestMode: true,
    metadata: {
      title: "Cash Gift",
      variable_name: "cash_gift",
      event_id: eventId,
      trans_type: "credit",
      user_id: userId,
      description: "paid for cash gift",
      logo: "",
    },
  };

  let initializePaymentWithMonnify = useMonnifyPayment(monnifyConfigData);

  const onSuccessWithMonnify = (reference: any) => {
    if (reference.paymentStatus === "PAID") {
      PayForCashGiftFn({
        variables: {
          payload: {
            currency: Currency.ngn,
            eventID: eventId,
            amount: Number(amount),
            status: true,
            fullName: fullName,
            phone: phoneNumberValue,
            email: email,
            reference: reference.transactionReference as string,
            paymentProcessor: paymentProcessor.monnify,
            creditorAlias: fullName,
          },
        },
      });
      toast.success(<p className="toast">Payment successful</p>);
    }
  };

  const payStackConfigMetaData = {
    user_id: userId,
    event_id: eventId,
    trans_type: "credit",
    currency: "NGN",
    description: "paid for cash gift",
    creditor_alias: fullName,
    charges: paystackCharge?.charge,
    amount: amount,
  };

  let paystackconfigData = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: parseInt(`${Number(amount) * 100}`),
    publicKey: process.env.NEXT_PUBLIC_PAYSTACK_PUBLIC_KEY || "",
    metadata: {
      ...payStackConfigMetaData,
      custom_fields: Object.entries(payStackConfigMetaData)?.map(([k, v]) => {
        return {
          display_name: k,
          variable_name: k,
          value: v,
        };
      }),
    },
  };

  let initializePaymentWithPaystack = usePaystackPayment(paystackconfigData);

  const onSuccessWithPaystack = (reference: any) => {
    PayForCashGiftFn({
      variables: {
        payload: {
          currency: Currency.ngn,
          eventID: eventId,
          amount: parseInt(`${finalAmount}`),
          status: true,
          fullName: fullName,
          phone: phoneNumberValue,
          email: email,
          reference: reference.reference as string,
          paymentProcessor: paymentProcessor.paystack,
        },
      },
    });
    toast.success(<p className="toast">Payment successful</p>);
  };

  const payWithPaystack: SubmitHandler<Values> = (data) => {
    setOpen(false);
    initializePaymentWithPaystack(onSuccessWithPaystack);
  };

  const payWithMonnify: SubmitHandler<Values> = (data) => {
    setOpen(false);
    initializePaymentWithMonnify(onSuccessWithMonnify);
  };

  return (
    <div>
      <div className={cashGift["cashGift--content"]}>
        <div className={cashGift["cashGift--content__top"]}>
          <h1>Cash Gift</h1>
        </div>

        <form className={cashGift["cashGift--content-form"]}>
          <div className={cashGift["cashGift--content-form_container"]}>
            <div className={cashGift["input"]}>
              <label htmlFor="fullName">Full Name</label>
              <input
                {...register("fullName")}
                type="text"
                name="fullName"
                placeholder="e.g Steve Bakery"
                className={cashGift["cashGift--content-form__field"]}
              />
            </div>
            <p className={cashGift["cashGift--content-form__errors"]}>
              {errors.fullName?.message} &nbsp;
            </p>
            <div className={cashGift["input"]}>
              <label htmlFor="email">Email</label>
              <input
                {...register("email")}
                type="text"
                className={cashGift["cashGift--content-form__field"]}
                name="email"
                placeholder="timi@gmail.com"
              />
            </div>
            <p className={cashGift["cashGift--content-form__errors"]}>
              {errors.email?.message}&nbsp;
            </p>
            <InputPhone
              name="phoneNumber"
              label="Phone Number"
              placeholder="Enter Phone Number (Whatsapp Number)"
              value={phoneNumberValue}
              setValue={setPhoneNumberValue}
            />
            <p className={cashGift["cashGift--content-form__errors"]}>&nbsp;</p>
            <div className={cashGift["input"]}>
              <label htmlFor="amount">Amount</label>
              <input
                {...register("amount")}
                type="number"
                name="amount"
                className={cashGift["cashGift--content-form__field"]}
                placeholder="Type the amount you want to gift"
              />
              <div className={cashGift["cashGift--content-form__note"]}>
                <span>
                  {amount
                    ? paystackCharge?.with100
                      ? "+1.5% + ₦100 processing fee"
                      : "+1.5% processing fee"
                    : ""}
                </span>
              </div>
            </div>
            <p className={cashGift["cashGift--content-form__errors"]}>
              {errors.amount?.message} &nbsp;
            </p>
          </div>

          <div className={cashGift["cashGift--content-form_container__group"]}>
            <button
              type="button"
              onClick={() => {
                setValue("amount", 5000, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
              className={
                cashGift["cashGift--content-form_container__group--btn"]
              }
            >
              5,000
            </button>
            <button
              type="button"
              onClick={() => {
                setValue("amount", 10000, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
              className={
                cashGift["cashGift--content-form_container__group--btn"]
              }
            >
              10,000
            </button>
            <button
              type="button"
              onClick={() => {
                setValue("amount", 20000, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
              className={
                cashGift["cashGift--content-form_container__group--btn"]
              }
            >
              20,000
            </button>
            <button
              type="button"
              onClick={() => {
                setValue("amount", 50000, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
              className={
                cashGift["cashGift--content-form_container__group--btn"]
              }
            >
              50,000
            </button>
            <button
              type="button"
              onClick={() => {
                setValue("amount", 100000, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
              className={
                cashGift["cashGift--content-form_container__group--btn"]
              }
            >
              100,000
            </button>
          </div>

          <div className={cashGift["cashGift--content-btnContainer"]}>
            <Button
              type="button"
              design="secondary"
              label="Pay via Paystack"
              handleClick={handleSubmit(payWithPaystack)}
              disabled={
                phoneNumberValue === "" || isSubmitting || !isDirty || !isValid
              }
            >
              <div>
                <Icon iconName="paystack" />
                <p className={cashGift["cashGift--content-btnContainer-p"]}>
                  {phoneNumberValue === "" ||
                  isSubmitting ||
                  !isDirty ||
                  !isValid
                    ? `Pay via Paystack`
                    : `Pay ₦${finalAmount}`}
                </p>
              </div>
            </Button>
            {/* <Button
            type="button"
            design="secondary"
            label="Pay via Monnify"
            handleClick={handleSubmit(payWithMonnify)}
            disabled={
              phoneNumberValue === "" || isSubmitting || !isDirty || !isValid
            }
            >
            <div>
              <Icon iconName="monnify" />
              <p className={cashGift["cashGift--content-btnContainer-p"]}>
                Pay Via Monnify
              </p>
            </div>
          </Button> 
          */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CashGiftPayment;
