import { shimmer, toBase64 } from "@src/utils/shimmer";
import Image from "next/image";
import React from "react";
import { motion } from "framer-motion";
import image from "../../../../public/images/image14.jpeg";
import Dialog from "src/components/Dialog";
import Button from "@src/components/Button";
import { MemoCashGiftModal as CashGiftModal } from "src/components/modals/CashGiftModal";
import { Currency, getForeignAccountDetails } from "@src/@types/api.d";
import { GetForeignWalletDetails } from "@src/graphql/queries";
import toast from "react-hot-toast";
import { useQuery } from "@apollo/client";

interface ICashGift {
  eventId: number;
  hasWallet?: boolean;
}

const CashGift = ({ eventId, hasWallet }: ICashGift) => {
  const [openCashGift, setOpenCashGift] = React.useState<boolean>(false);

  const { data: foreignWalletDetails, loading: _foreignDetailsLoading } =
    useQuery<getForeignAccountDetails>(GetForeignWalletDetails, {
      skip: !eventId,
      variables: {
        eventId: !!eventId ? eventId : 0,
      },
      fetchPolicy: "network-only",
      onError(error) {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      },
    });

  const foreignDetails = React.useMemo(() => {
    const res = foreignWalletDetails?.getForeignAccountDetails.find(
      (item) => item?.currency === Currency.gpb || Currency.usd
    );
    return res;
  }, [foreignWalletDetails]);

  return (
    <div className="w-full h-max flex items-center relative min-h-[30.875rem] ">
      <div className="absolute w-full h-full overflow-hidden">
        <motion.div
          initial={{ scale: 1 }}
          animate={{
            scale: 1.1,
            transition: {
              delay: 0.3,
              duration: 0.8,
              ease: [0.79, 0.14, 0.15, 0.86],
            },
          }}
          className={
            "relative w-full h-full after:absolute after:w-full after:h-full after:bg-bg-cashgift-t2"
          }
        >
          <Image
            placeholder="blur"
            loading="eager"
            layout="fill"
            blurDataURL={`data:image/svg+xml;base64,${toBase64(
              shimmer(700, 475)
            )}`}
            src={image}
            alt="gallery-image"
            className="object-cover"
          />
        </motion.div>
      </div>
      <div className="w-full relative min-h-full h-full container py-[1.5rem] md:py-[7.5rem] flex flex-col justify-center items-center">
        <h4 className="mb-4 text-center text-white font-cinzel text-[1.5rem] md:text-[2.25rem] leading-[150%] font-[700] uppercase max-w-[63rem]">
          Your presence on our special day is the most cherished gift.
        </h4>
        <p className="mb-[3rem] text-white text-center md:text-start font-temp text-[1rem] md:text-[1.25rem] font-[300] leading-[150%]">
          If you wish to contribute in a way beyond your warm wishes, we
          gratefully welcome cash gifts.
        </p>
        <Dialog
          fillScreen={false}
          trigger={
            <Button type="button" label="Give a cash gift" design="template-2">
              <span className="!text-white">Give a cash gift</span>
            </Button>
          }
          open={openCashGift}
          onOpenChange={setOpenCashGift}
        >
          <CashGiftModal
            foreignDetails={foreignDetails}
            eventId={eventId}
            setOpen={setOpenCashGift}
          />
        </Dialog>
      </div>
    </div>
  );
};

export default CashGift;
