import * as React from "react";
import { useWatch, useForm, SubmitHandler } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@src/components/Button";
import item from "./item.module.scss";
import InputPhone from "@src/components/InputPhone";
import toast from "react-hot-toast";
import { useMonnifyPayment } from "react-monnify";
import { usePaystackPayment } from "react-paystack";
import { useMutation } from "@apollo/client";
import {
  Currency,
  payForWishListItemV1,
  payForWishListItemV1Variables,
  paymentProcessor,
} from "@src/@types/api.d";
import { PayForWishListItemV1 } from "@src/graphql/mutations";
import { getCookie } from "cookies-next";
import Icon from "@src/components/Icon";

interface Values {
  fullName: string;
  email: string;
  amount: number | string;
}

const GeneralFormSchema = Yup.object().shape({
  fullName: Yup.string().required("Full name is required"),
  email: Yup.string().email().required("Email is required"),
  amount: Yup.string().required("Amount is required"),
});

interface ItemProps {
  title: string;
  id: number;
  amount: number;
  eventId: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenItem: React.Dispatch<React.SetStateAction<boolean>>;
}

const Item = ({
  title,
  id,
  eventId,
  amount,
  setOpen,
  setOpenItem,
}: ItemProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<Values>({
    mode: "onChange",
    defaultValues: {
      fullName: "",
      email: "",
      amount: `${amount}`,
    },
    resolver: yupResolver(GeneralFormSchema),
  });
  const amountToPay = useWatch({ control, name: "amount" });
  const fullName = useWatch({ control, name: "fullName" });
  const email = useWatch({ control, name: "email" });
  const [phoneNumberValue, setPhoneNumberValue] = React.useState<string>("");

  const userId = getCookie("usI") as string;

  const [PayForWishlistFn] = useMutation<
    payForWishListItemV1,
    payForWishListItemV1Variables
  >(PayForWishListItemV1, {
    onError(error) {
      if (error.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      }
    },
  });

  const paystackCharge = React.useMemo(() => {
    const preTotal = parseInt(`${amountToPay}`);
    const percentCharge = 0.015 * preTotal;
    const fullCharge = percentCharge + 100;
    if (preTotal < 2500) {
      return { charge: percentCharge };
    } else if (fullCharge > 2000) {
      return { charge: 2000, with100: true };
    } else {
      return { charge: fullCharge, with100: true };
    }
  }, [amountToPay]);

  const finalAmount = React.useMemo(() => {
    const paystackFeeRate = 0.015;
    let paystackFixedFee = 0;
    const feeCap = 2000;
    if (Number(amountToPay) >= 2500) {
      paystackFixedFee = 100.0;
    }
    const applicableFee =
      paystackFeeRate * Number(amountToPay) + paystackFixedFee;
    const final =
      applicableFee > feeCap
        ? Number(amountToPay) + feeCap
        : (Number(amountToPay) + paystackFixedFee) / (1 - paystackFeeRate) +
          0.01;
    const res = parseFloat(final.toFixed(2));
    return res;
  }, [amountToPay]);

  let monnifyConfigData = {
    apiKey: process.env.NEXT_PUBLIC_MONNIFY_API_ENV!,
    reference: new Date().getTime().toString(),
    amount: Number(amountToPay) || amount,
    currency: "NGN",
    paymentMethods: ["CARD", "ACCOUNT_TRANSFER"],
    customerFullName: fullName,
    customerEmail: email,
    contractCode: process.env.NEXT_PUBLIC_MONNIFY_CONTRACT_CODE!,
    customerMobileNumber: "phoneNumberValue",
    isTestMode: true,
    metadata: {
      title: "Wishlist Item",
      variable_name: "wishlist_item",
      user_id: userId,
      event_id: eventId,
      trans_type: "credit",
      description: "paid for wishlist",
      item_id: id,
      item_name: title,
      logo: "",
    },
  };

  let initializePaymentWithMonnify = useMonnifyPayment(monnifyConfigData);

  const onSuccessWithMonnify = (reference: any) => {
    if (reference.paymentStatus === "PAID") {
      PayForWishlistFn({
        variables: {
          payload: {
            currency: Currency.ngn,
            itemId: `${id}`,
            eventId: eventId,
            amount: Number(amountToPay) || amount,
            status: true,
            itemName: title,
            full_name: fullName,
            phone: phoneNumberValue,
            email: email,
            reference: reference.transactionReference as string,
            paymentProcessor: paymentProcessor.monnify,
            creditorAlias: fullName,
          },
        },
      });
      toast.success(<p className="toast">Payment successful</p>);
    }
  };

  const payWithMonnify: SubmitHandler<Values> = () => {
    setOpen(false);
    setOpenItem(false);
    initializePaymentWithMonnify(onSuccessWithMonnify);
  };

  const payStackConfigMetaData = {
    user_id: userId,
    event_id: eventId,
    trans_type: "credit",
    currency: "NGN",
    description: "Paid for wishlist",
    item_id: id,
    item_name: title,
    creditor_alias: fullName,
    charges: paystackCharge?.charge,
    amount: amount,
  };

  let payStackConfigData = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: parseInt(`${Number(amountToPay) * 100}`),
    publicKey: process.env.NEXT_PUBLIC_PAYSTACK_PUBLIC_KEY || "",
    metadata: {
      ...payStackConfigMetaData,
      custom_fields: Object.entries(payStackConfigMetaData)?.map(([k, v]) => {
        return {
          display_name: k,
          variable_name: k,
          value: v,
        };
      }),
    },
  };

  let initializePaymentWithPaystack = usePaystackPayment(payStackConfigData);

  const onSuccessWithPaystack = (reference: any) => {
    PayForWishlistFn({
      variables: {
        payload: {
          currency: Currency.ngn,
          itemId: `${id}`,
          eventId: eventId,
          amount: parseInt(`${finalAmount}`),
          status: true,
          itemName: title,
          full_name: fullName,
          phone: phoneNumberValue,
          email: email,
          reference: reference.reference as string,
          paymentProcessor: paymentProcessor.paystack,
        },
      },
    });
    toast.success(<p className="toast">Payment successful</p>);
  };

  const payWithPaystack: SubmitHandler<Values> = () => {
    setOpen(false);
    setOpenItem(false);
    initializePaymentWithPaystack(onSuccessWithPaystack);
  };

  return (
    <div className={item["item--content"]}>
      <div className={item["item--content__top"]}>
        <h1>{title}</h1>
      </div>

      <form className={item["item--content-form"]}>
        <div className={item["item--content-form_container"]}>
          <div className={item["input"]}>
            <label htmlFor="fullName">Full Name</label>
            <input
              {...register("fullName")}
              type="text"
              name="fullName"
              placeholder="e.g Steve Bakery"
              className={item["item--content-form__field"]}
            />
          </div>
          <p className={item["item--content-form__errors"]}>
            {errors.fullName?.message} &nbsp;
          </p>
          <div className={item["input"]}>
            <label htmlFor="email">Email</label>
            <input
              {...register("email")}
              type="email"
              className={item["item--content-form__field"]}
              name="email"
              placeholder="timi@gmail.com"
            />
          </div>
          <p className={item["item--content-form__errors"]}>
            {errors.email?.message}&nbsp;
          </p>
          <InputPhone
            name="phoneNumber"
            label="Phone Number"
            placeholder="Enter Phone Number (Whatsapp Number)"
            value={phoneNumberValue}
            setValue={setPhoneNumberValue}
          />
          <p className={item["item--content-form__errors"]}>&nbsp;</p>
          <div className={item["input"]}>
            <label htmlFor="amount">Amount</label>
            <input
              {...register("amount")}
              type="number"
              name="amount"
              className={item["item--content-form__field"]}
              placeholder="100000"
            />
            <div className={item["item--content-form__note"]}>
              <span>
                {amount
                  ? paystackCharge?.with100
                    ? "+1.5% + ₦100 processing fee"
                    : "+1.5% processing fee"
                  : ""}
              </span>
            </div>
          </div>
          <p className={item["item--content-form__errors"]}>
            {errors.amount?.message} &nbsp;
          </p>
        </div>
        <div className={item["item--content-btnContainer"]}>
          <Button
            type="button"
            design="secondary"
            label="Pay via Paystack"
            handleClick={handleSubmit(payWithPaystack)}
            disabled={phoneNumberValue === "" || isSubmitting || !isValid}
          >
            <div>
              <Icon iconName="paystack" />
              <p className={item["item--content-btnContainer-p"]}>
                {phoneNumberValue === "" || !isValid
                  ? `Pay via Paystack`
                  : `Pay ₦${finalAmount}`}
              </p>
            </div>
          </Button>
          {/* <Button
            type="button"
            design="secondary"
            label="Pay via Monnify"
            handleClick={handleSubmit(payWithMonnify)}
            disabled={phoneNumberValue === "" || isSubmitting || !isValid}
          >
            <div>
              <Icon iconName="monnify" />
              <p className={item["item--content-btnContainer-p"]}>
                Pay via Monnify
              </p>
            </div>
          </Button> */}
        </div>
      </form>
    </div>
  );
};

export default Item;
