import { getLiveSchedule } from "@src/@types/api";
import React from "react";
import { twMerge } from "tailwind-merge";
import Icon from "../Icon";
import moment from "moment";
import Link from "next/link";
import { getCookie } from "cookies-next";

interface ILiveSchedules {
  schedules: getLiveSchedule;
}

const LiveSchedules = ({ schedules }: ILiveSchedules) => {
  const [refreshToken, setRefreshToken] = React.useState<string>("");

  React.useEffect(() => {
    setRefreshToken(getCookie("evd") as string);
  }, []);

  return (
    <div className="h-screen w-full md:w-[95vw] md:h-[80vh] bg-white md:rounded-sm overflow-auto py-[3rem]">
      <div className="w-full h-full flex items-start justify-center flex-wrap gap-x-[15rem] gap-y-[2rem] lg:max-w-[80rem] mx-auto">
        {schedules?.getLiveSchedule?.data?.map((i, idx) => (
          <div
            key={idx}
            className={twMerge(
              "flex flex-col items-center justify-center gap-[1.5rem]"
            )}
          >
            <div className="flex flex-col items-center">
              <Icon iconName="roses" />
              <h3 className="text-[1.5rem] font-[500]">
                {i?.event_part?.name}
              </h3>
            </div>
            <span className="font-[300]">
              {moment(i?.time).format("MMMM Do YYYY")}
            </span>
            <span className="font-[300]">
              {" "}
              {moment(i?.time).format("h:mm a")}
            </span>
            <Link
              passHref
              href={`${process.env.NEXT_PUBLIC_INAWO_STREAM_BASE_URL}?action=youtube&key=${i?.event_uuid}&schedule=${i?.id}&token=${refreshToken}`}
            >
              <a target="_blank">
                <button className="bg-inawo-gold-300 rounded-[0.125rem] h-[2.75rem] px-[1.5rem] flex items-center gap-[0.5rem] border-solid !border border-black mb-[0.75rem] hover:opacity-90 ease-in-out duration-300 transition-opacity">
                  <Icon iconName="videoCamera" />
                  <span className="">Watch Live</span>
                </button>
              </a>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LiveSchedules;
