import {
  Currency,
  getForeignAccountDetails_getForeignAccountDetails,
} from "@src/@types/api.d";
import Icon from "@src/components/Icon";
import { copyToClipboard, objectKeyToWord } from "@src/utils/general";
import * as React from "react";
import styles from "../cashGift.module.scss";

interface ForeignDetailsInterface {
  foreignDetails:
    | getForeignAccountDetails_getForeignAccountDetails
    | undefined
    | null;
}

const ForeignDetails = ({ foreignDetails }: ForeignDetailsInterface) => {
  return (
    <div className={styles["cashGift--content"]}>
      <div className={styles["cashGift--content__top"]}>
        <h1>{`${
          foreignDetails?.currency === Currency.usd ? `Dollar` : `Pound`
        } Account Details`}</h1>
      </div>
      <div className={styles["cashGift--content__foreignDetails"]}>
        {Object.entries(foreignDetails ?? {})
          ?.filter(
            ([key, value]) =>
              !(key === "eventId" || key === "__typename" || key === "walletId")
          )
          .map(([key, value]) => (
            <div
              className={styles["cashGift--content__foreignDetails__detail"]}
            >
              <div className={styles["name"]}>{objectKeyToWord(key)}</div>
              <div
                className={styles["value"]}
                onClick={() => {
                  copyToClipboard(value, `${key} has been copied!`);
                }}
              >
                {" "}
                <span>{value}</span> <Icon iconName="copyIcon" />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ForeignDetails;
