import { IHero } from "@src/components/Hero";
import { shimmer, toBase64 } from "@src/utils/shimmer";
import { getCookie } from "cookies-next";
import { useTransform, useViewportScroll, motion } from "framer-motion";
import Image from "next/image";
import React from "react";
import image from "../../../../public/images/image11.jpeg";
import weddingLogo from "../../../../public/images/template2PilotLogoLight.png";

const Hero: React.FC<IHero> = ({
  eventId,
  heroImageData,
  slugData,
  loading,
}) => {
  const { scrollY } = useViewportScroll();
  const y1 = useTransform(scrollY, [0, 300], [0, 60]);
  const currentAccessToken = getCookie("tsl") as string;
  const [show, setShow] = React.useState<boolean>(false);

  React.useEffect(() => {
    setShow(true);
  }, []);

  return (
    <div className="w-full h-[100vh] relative overflow-hidden">
      <motion.div
        initial={{ scale: 1 }}
        animate={{
          scale: 1.1,
          transition: {
            delay: 0.3,
            duration: 0.8,
            ease: [0.79, 0.14, 0.15, 0.86],
          },
        }}
        className={
          "relative top-0 bottom-0 right-0 left-0 w-full h-full after:absolute after:w-full after:h-full after:bg-template-2-hero"
        }
      >
        <Image
          placeholder="blur"
          loading="eager"
          layout="fill"
          blurDataURL={`data:image/svg+xml;base64,${toBase64(
            shimmer(700, 475)
          )}`}
          src={
            // !!heroImageData.getHeroImage ? heroImageData.getHeroImage :
            image
          }
          alt="gallery-image"
          className="object-cover"
        />
      </motion.div>
      <div className="w-full h-full absolute left-0 top-0 z-[4]">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: {
              delayChildren: 1,
              staggerChildren: 1.5,
            },
          }}
          viewport={{ once: true }}
          className={"w-full h-full flex flex-col justify-center items-center"}
        >
          <motion.h5
            initial={{ opacity: 0, y: 30 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 1,
                ease: [0.79, 0.14, 0.15, 0.86],
              },
            }}
            className={
              "text-[2rem] md:text-[4rem] text-white text-center font-cinzel mb-[1.5rem] leading-[100%]"
            }
          >
            KHAIRAT & SHERIFF
          </motion.h5>
          <motion.p
            initial={{ opacity: 0, y: 30 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 1,
                ease: [0.79, 0.14, 0.15, 0.86],
              },
            }}
            className="font-suranna text-[1.25rem] md:text-[2rem] capitalize text-white leading-[100%] mb-[3rem]"
          >
            April 20, 2024
          </motion.p>
          <motion.div className="relative h-[19%] w-full max-w-[29.3rem] mb-4 md:mb-0">
            <Image
              placeholder="blur"
              loading="eager"
              layout="fill"
              src={weddingLogo}
              alt="gallery-image"
              className="object-contain"
            />
          </motion.div>
          <motion.p
            initial={{ opacity: 0, y: 30 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 1,
                ease: [0.79, 0.14, 0.15, 0.86],
              },
            }}
            className="text-[2rem] md:text-[4rem] text-white leading-[120%] tracking-[0.2rem] font-dacing"
          >
            #theKSnuptials24
          </motion.p>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
