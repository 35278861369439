import * as React from "react";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import backstoryModal from "./backstoryModal.module.scss";

interface MessageModalProps {
  story: string;
  role: string;
}

const BackstoryModal: React.FC<MessageModalProps> = (props) => {
  return (
    <div className={backstoryModal["backstoryModal--content"]}>
      <div className={backstoryModal["backstoryModal--content__top"]}>
        <h1>{props.role ? `${props.role}'s ` : ""} story</h1>
      </div>
      <ScrollArea.Root
        className={backstoryModal["backstoryModal--content__scroll"]}
      >
        <ScrollArea.Viewport
          className={backstoryModal["backstoryModal--content__bottom"]}
        >
          <article
            className={backstoryModal["backstoryModal--content__article"]}
          >
            {props.story}
          </article>
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar
          className={backstoryModal["backstoryModal--content__scroll__bar"]}
          orientation="vertical"
        >
          <ScrollArea.Thumb
            className={backstoryModal["backstoryModal--content__scroll__thumb"]}
          />
        </ScrollArea.Scrollbar>
        <ScrollArea.Scrollbar
          className={backstoryModal["backstoryModal--content__scroll__bar"]}
          orientation="horizontal"
        >
          <ScrollArea.Thumb
            className={backstoryModal["backstoryModal--content__scroll__thumb"]}
          />
        </ScrollArea.Scrollbar>
        {/* <ScrollArea.Corner /> */}
      </ScrollArea.Root>
    </div>
  );
};

export default BackstoryModal;
