import Button from "@src/components/Button";
import { IParts } from "@src/components/Parts";
import { shimmer, toBase64 } from "@src/utils/shimmer";
import { getCookie } from "cookies-next";
import Image from "next/image";
import React from "react";
import handshakes from "../../../../public/images/image15.jpeg";
import { motion } from "framer-motion";
import AddToCalendar from "@culturehq/add-to-calendar";
import { getTimeRemaining } from "@src/utils/general";

const Parts: React.FC<IParts> = ({
  eventId,
  eventPartsData,
  liveSchedules,
}) => {
  const currentAccessToken = getCookie("tsl") as string;
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setShow(true);
  }, []);

  const addOneDay = (dateTime: string) => {
    var date = new Date(dateTime);
    date.setHours(date.getHours() + 24);
    var isoDate = date.toISOString();
    return isoDate;
  };

  return !!eventPartsData?.eventParts &&
    eventPartsData?.eventParts?.length > 0 ? (
    <div className="container w-full py-[3rem] md:py-[7.5rem] lg:pl-[calc(3rem+7.5rem)] text-inawo-black-900/80">
      <div className="relative w-full h-max lg:py-[5rem] lg:px-[3rem]  border-0 lg:border-[3px] border-inawo-blue-100 ">
        <motion.div className="absolute w-[calc(100%-37rem)] h-[25.32rem]  bottom-1/2 translate-y-[50%]  bg-slate-400 left-0 -ml-[7.5rem] hidden lg:flex">
          <motion.div
            initial={{ scale: 1 }}
            animate={{
              scale: 1.1,
              transition: {
                delay: 0.3,
                duration: 0.8,
                ease: [0.79, 0.14, 0.15, 0.86],
              },
            }}
            className="relative w-full h-full"
          >
            <Image
              placeholder="blur"
              loading="eager"
              layout="fill"
              blurDataURL={`data:image/svg+xml;base64,${toBase64(
                shimmer(700, 475)
              )}`}
              src={handshakes}
              alt="gallery-image"
              className="object-cover"
            />
          </motion.div>
        </motion.div>
        <motion.div
          initial={{ opacity: 1 }}
          whileInView={{
            opacity: 1,
            transition: {
              delayChildren: 0.5,
              staggerChildren: 0.5,
            },
          }}
          className="w-full lg:max-w-[37rem] ml-auto flex flex-col gap-[4rem]"
        >
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                delay: 0.2,
                duration: 1,
                ease: [0.79, 0.14, 0.15, 0.86],
              },
            }}
            className="flex flex-col gap-1"
          >
            <h4 className="uppercase font-cinzel text-[1.5rem] md:text-[2.25rem]  font-[700] leading-[150%] ">
              love is in the air
            </h4>
            <p className="font-temp text-[1rem] md:text-[1.25rem] font-[300] leading-[140%]">
              We are getting married and with joy in our hearts, we invite you
              to celebrate the beginning of our happily ever after.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                delay: 0.2,
                duration: 1,
                ease: [0.79, 0.14, 0.15, 0.86],
              },
            }}
            className="flex flex-col gap-4"
          >
            <span className="font-cinzel leading-[100%] uppercase text-[1.25rem] md:text-[1.5rem]">
              Saturday, 20th of April, 2024
            </span>
            <span className="font-temp text-[1rem] md:text-[1.25rem] font-[300] leading-[100%]">
              Cedar Point Event Centre
            </span>
            <p className="font-temp text-[1rem] md:text-[1.25rem] font-[300] leading-[100%]">
              4, New Era Road, Iyana-Ipaja, Lagos
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                delay: 0.2,
                duration: 1,
                ease: [0.79, 0.14, 0.15, 0.86],
              },
            }}
            className="flex flex-col gap-4"
          >
            <span className="font-cinzel leading-[100%] uppercase text-[1.25rem] md:text-[1.5rem]">
              The count down has begun
            </span>

            <div className="flex gap-4">
              {[
                `${getTimeRemaining(new Date("04/20/2024"))?.days}days`,
                `${getTimeRemaining(new Date("04/20/2024"))?.hours}hrs`,
                `${getTimeRemaining(new Date("04/20/2024"))?.minutes}mins`,
                `${getTimeRemaining(new Date("04/20/2024"))?.seconds}sec`,
              ]?.map((i, idx) => (
                <span
                  key={idx}
                  className="font-temp text-[1rem] md:text-[1.25rem] font-[300] leading-[100%]"
                >
                  {i}
                </span>
              ))}
            </div>
          </motion.div>
          <AddToCalendar
            event={{
              name: ``,
              details: null,
              location: ``,
              startsAt: `${new Date()}`,
              endsAt: `${new Date()}`,
            }}
          >
            Add to Calender
          </AddToCalendar>
        </motion.div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Parts;
