import { IParts } from "@src/components/Parts";
import moment from "moment";
import React from "react";

const Schedule: React.FC<IParts> = ({
  eventId,
  eventPartsData,
  liveSchedules,
}) => {
  return !!eventPartsData?.eventParts &&
    eventPartsData?.eventParts?.length > 0 ? (
    <div className="container w-full py-[1.5rem] md:py-[6.5rem] flex flex-col justify-center items-center text-inawo-black-900/80">
      <h4 className="text-[1.5rem] font-cinzel md:text-[2.25rem] font-[700] leading-[100%] uppercase mb-[3rem]">
        Our wedding SCHEDULE
      </h4>
      <ul className="flex flex-col gap-[1.5rem]">
        {eventPartsData?.eventParts?.map((i, idx) => (
          <li key={idx} className="flex flex-col items-center gap-[1.5rem]">
            <span className="font-cinzel text-[1.25rem] md:text-[1.5rem]">
              {i?.name}
            </span>
            <span className="font-temp text-[1rem] md:text-[1.25rem] leading-[100%]">
              {moment(i?.time)?.format("LT")}
            </span>
            {idx !== eventPartsData?.eventParts?.length - 1 ? (
              <div className="h-[2.275rem] border-l border-l-inawo-pink-200"></div>
            ) : (
              <></>
            )}
          </li>
        ))}
        <div className="w-[13rem] mx-auto border-t border-t-inawo-pink-200" />
        <div className="flex flex-col gap-4 items-center">
          <h4 className="font-cinzel text-[1.25rem] md:text-[1.5rem] uppercase">
            Location
          </h4>
          <span className="font-temp text-[1rem] md:text-[1.25rem] ">
            Cedar Point Event Centre
          </span>
          <span className="font-temp text-[1rem] md:text-[1.25rem] ">
            4, New Era Road, Iyana-Ipaja, Lagos
          </span>
        </div>
      </ul>
    </div>
  ) : (
    <></>
  );
};

export default Schedule;
