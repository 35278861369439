import * as React from "react";
import styles from "./backstory.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectCreative, Pagination, Autoplay, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/effect-creative";
import Image from "next/image";
import { getBackStory } from "@src/@types/api.d";
import { shimmer, toBase64 } from "@src/utils/shimmer";
import Dialog from "src/components/Dialog";
import BackstoryModal from "../modals/BackstoryModal";

export type IBackStory = {
  eventId: number;
  backStoryData: getBackStory;
  loading?: boolean;
};

const Backstory: React.FC<IBackStory> = ({ eventId, backStoryData }) => {
  return !!backStoryData?.getBackStory &&
    backStoryData?.getBackStory?.length > 0 ? (
    <Swiper
      loop
      modules={[Navigation, EffectCreative, Pagination, Autoplay, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      grabCursor={true}
      effect={"creative"}
      creativeEffect={{
        prev: {
          shadow: true,
          translate: [0, 0, -400],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      }}
      autoplay={{
        delay: 20000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      className={styles["home__knowthecouple"]}
    >
      {backStoryData?.getBackStory?.map((story, idx) => (
        <SwiperSlide
          key={`Swiper-slide-${idx}`}
          className={styles["home__knowthecouple--inner"]}
        >
          <div className={styles["home__knowthecouple--container"]}>
            <div className={styles["home__knowthecouple--container-inner"]}>
              <div className={styles["card"]}>
                <div className={styles["card__image-husband"]}>
                  {!!story?.image && (
                    <Image
                      placeholder="blur"
                      blurDataURL={`data:image/svg+xml;base64,${toBase64(
                        shimmer(700, 475)
                      )}`}
                      layout="fill"
                      objectFit="cover"
                      src={story?.image}
                      alt="Backstory"
                    />
                  )}
                </div>
                <div className={styles["home__knowthecouple--container-deck"]}>
                  <p>{story?.role}</p>
                  <article>{story?.story}</article>
                  <Dialog
                    trigger={
                      <div
                        className={
                          styles[
                            "home__knowthecouple--container-deck__view-more"
                          ]
                        }
                      >
                        View more
                      </div>
                    }
                  >
                    <BackstoryModal
                      story={story?.story ?? ""}
                      role={story?.role ?? ""}
                    />
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  ) : (
    <></>
  );
};

export default Backstory;
