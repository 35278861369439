import toast from "react-hot-toast";

export const copyToClipboard = (text: string, alert: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast.success(<p className="toast">{alert}</p>);
    })
    .catch((err) => {
      toast.error(<p>An Error occurred while copying</p>);
    });
};

export const objectKeyToWord = (key: string) => {
  const res: string[] = [];
  key
    .split("_")
    .forEach((i) => res.push(`${i[0].toUpperCase()}${i.slice(1, i.length)}`));
  return res.join(" ");
};

export const formatMoney = (n: number) => {
  const res = new Intl.NumberFormat().format(n);
  return res;
};

export function getTimeRemaining(endDate: Date): {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
} {
  const now = new Date();
  const timeRemaining = endDate.getTime() - now.getTime();

  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
}
